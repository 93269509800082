export const BUS_SEARCH_HISTORY_LOCAL_STORAGE_KEY = "bus-search-history";
export const BUS_ROUTE_HISTORY_LOCAL_STORAGE_KEY = "bus-route-history";

export const BUS_SUGGESTIONS = [
  {
    code: "11320000",
    province: "تهران",
    city: "تهران",
    cityEn: "Tehran",
    name: "تهران",
    isAbroad: false,
    childs: []
  },
  {
    code: "21310000",
    province: "اصفهان",
    city: "اصفهان",
    cityEn: "Esfahan",
    name: "اصفهان",
    isAbroad: false,
    childs: []
  },
  {
    code: "26310000",
    province: "آذربایجان شرقی",
    city: "تبریز",
    cityEn: "Tabriz",
    name: "تبریز",
    isAbroad: false,
    childs: []
  },
  {
    code: "41310000",
    province: "فارس",
    city: "شیراز",
    cityEn: "Shiraz",
    name: "شیراز",
    isAbroad: false,
    childs: []
  },
  {
    code: "14310000",
    province: "قم",
    city: "قم",
    cityEn: "Qom",
    name: "قم",
    isAbroad: false,
    childs: []
  },
  {
    code: "51310000",
    name: "اراک",
    city: "اراک",
    cityEn: "Arak",
    province: "مرکزی",
    childs: [],
    isAbroad: false
  },
  {
    code: "54310000",
    province: "گیلان",
    city: "رشت",
    cityEn: "Rasht",
    name: "رشت",
    isAbroad: false,
    childs: []
  }
];
