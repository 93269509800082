import { Button, LazyImage } from "@website/ui";
import { deepCompare } from "@website/utils";
import { omit } from "lodash";
import { useRouter } from "next/router";
import { memo } from "react";
import emptyResult from "../assets/empty-result.png";
import { ReserveCommentFilterEmptyResultTypes } from "./reserve-comment-filter-empty-result.types";

const Component = ({ onLoadFilter }: ReserveCommentFilterEmptyResultTypes) => {
  const router = useRouter();
  return (
    <div className="flex flex-col items-center justify-center gap-6">
      <LazyImage src={emptyResult?.src} />
      <div className="flex flex-col items-center gap-2">
        <div className="text-subtitle-1">نتیجه‌ای یافت نشد.</div>
        <div className="text-caption text-on-surface-medium-emphasis">
          متاسفانه در حال حاضر نظری متناسب با فیلتر های انتخابی شما وجود ندارد.
        </div>
      </div>
      <Button
        color="secondary"
        onClick={() => {
          if (onLoadFilter) {
            onLoadFilter({ attitude: "all" });
          }
          router.push(
            {
              pathname: router.pathname,
              query: {
                ...omit(
                  router?.query,
                  Object.keys(router?.query).filter((item) =>
                    item.startsWith("filter_")
                  )
                )
              }
            },
            undefined,
            { shallow: true, scroll: false }
          );
        }}
        variant="outline"
      >
        نمایش همه نظرات
      </Button>
    </div>
  );
};
export const ReserveCommentsFilterEmptyResult = memo(Component, deepCompare);
