/* eslint-disable react/jsx-no-target-blank */
/* eslint-disable react/style-prop-object */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/ban-ts-comment */
import { MaterialIcon } from "@website/icons";
import { useTranslation } from "@website/locale";
import { Button, LazyImage } from "@website/ui";
import { numberConvert } from "@website/utils";
import { FC } from "react";
import { FooterRow } from "./footer-row";
import {
  applications,
  contactUs,
  copyright,
  snappSocialMediaLinks,
  socialMediaLinks,
  support
} from "./footer.constants";
import { useFooter } from "./footer.hook";
import styles from "./footer.module.scss";
import { FooterPropertiesType } from "./footer.types";

export const FooterComponent: FC<FooterPropertiesType> = (properties) => {
  const channel = process.env.NEXT_PUBLIC_CHANNEL_TYPE || "web";
  const {
    minimalView,
    isShowSupportNumber = !["b2b"].includes(channel),
    isShowBolderPlateLinks = !["b2b"].includes(channel),
    hasB2BContact = ["b2b"].includes(channel),
    hasDownloadApp = !["b2b", "snapp-ir"].includes(channel),
    hasLinkBadge = !["b2b", "snapp-ir"].includes(channel)
  } = properties;
  const {
    certificates,
    // categories,
    links,
    moreInfo,
    services,
    linksBadgs
  } = useFooter();

  const { t, locale } = useTranslation();

  return (
    <footer className={styles["footer"]}>
      {isShowSupportNumber && (
        <FooterRow>
          <div className={styles["support"]}>
            <div className={styles["support-title"]}>{t("footer.title")}</div>
            <div className={styles["support-phone"]}>
              <a
                className={styles["support-phone-text"]}
                href={`tel:${support.phoneNumber.value}`}
              >
                {numberConvert(support.phoneNumber.text, {
                  numberOnly: false,
                  locale
                })}
              </a>
              <div className={styles["support-phone-icon"]}>
                <MaterialIcon name="phone" />
              </div>
            </div>
          </div>
        </FooterRow>
      )}
      {!minimalView && isShowBolderPlateLinks && (
        <FooterRow>
          <div className={styles["links"]}>
            {channel !== "snapp-ir" && (
              <div className={styles["links-column"]}>
                <div className={styles["links-column-title"]}>
                  {t("footer.services.title")}
                </div>
                <div className={styles["links-column-list"]}>
                  {services.map((item) => (
                    <a
                      className={styles["links-column-item"]}
                      href={item.url}
                      key={item.url}
                    >
                      {item.title}
                    </a>
                  ))}
                  <p className={styles["links-column-item-temp-train"]}>
                    {t("footer.services.train")}
                  </p>
                </div>
              </div>
            )}
            {channel !== "snapp-ir" && (
              <div className={styles["links-column"]}>
                <div className={styles["links-column-title"]}>
                  {t("footer.features.title")}
                </div>
                <div className={styles["links-column-list"]}>
                  {links.map((item) => (
                    <a
                      className={styles["links-column-item"]}
                      href={item.url}
                      itemScope
                      key={item?.url}
                    >
                      {item.title}
                    </a>
                  ))}
                </div>
              </div>
            )}
            {/* <div className={styles["links-column"]}>
            <div className={styles["links-column-title"]}>
              {t("footer.categories.title")}
            </div>
            <div className={styles["links-column-list"]}>
              {categories.map((item, index) => (
                <a
                  href={item.url}
                  key={index}
                  className={styles["links-column-item"]}
                >
                  {item.title}
                </a>
              ))}
            </div>
          </div> */}
            <div className={styles["links-column"]}>
              <div className={styles["links-column-title"]}>
                {t("footer.more_info.title")}
              </div>
              <div className={styles["links-column-list"]}>
                {moreInfo.map((item) => (
                  <a
                    className={styles["links-column-item"]}
                    href={item.url}
                    itemScope
                    key={item?.url}
                  >
                    {item.title}
                  </a>
                ))}
              </div>
            </div>
          </div>
        </FooterRow>
      )}

      <FooterRow>
        <div className={styles["contact"]}>
          <div className={styles["contact-us"]}>
            <div className={styles["contact-us-title"]}>
              {t("footer.contact_us.title")}
            </div>
            <div className={styles["contact-us-item"]}>
              <div className={styles["contact-us-item-key"]}>
                {channel !== "b2b" && <>{t("footer.contact_us.address")}:</>}
              </div>
              <div className={styles["contact-us-item-value"]}>
                {channel === "b2b"
                  ? t("footer.contact_us.contact_title")
                  : t("footer.contact_us.address_value")}
              </div>
            </div>
            {!hasB2BContact && (
              <>
                <div className={styles["contact-us-item"]}>
                  <div className={styles["contact-us-item-key"]}>
                    {t("footer.contact_us.zip_code")}:
                  </div>
                  <div className={styles["contact-us-item-value"]}>
                    {numberConvert(contactUs.zipCode, {
                      numberOnly: false,
                      locale
                    })}
                  </div>
                </div>
                <div className={styles["contact-us-item"]}>
                  <div className={styles["contact-us-item-key"]}>
                    {t("footer.contact_us.phone_number")}:
                  </div>
                  <div className={styles["contact-us-item-value"]}>
                    <a
                      color="default"
                      href={`tel:${contactUs.phoneNumbers[0].value}`}
                      itemScope
                    >
                      {numberConvert(contactUs.phoneNumbers[0].text, {
                        numberOnly: false,
                        locale
                      })}
                    </a>
                    &nbsp; - &nbsp;
                    <a
                      href={`tel:${contactUs.phoneNumbers[1].value}`}
                      itemScope
                    >
                      {numberConvert(contactUs.phoneNumbers[1].text, {
                        numberOnly: false,
                        locale
                      })}
                    </a>
                    &nbsp; (
                    {numberConvert(contactUs.phoneNumberPrefix, {
                      numberOnly: false,
                      locale
                    })}
                    )
                  </div>
                </div>
              </>
            )}
            {hasB2BContact && (
              <>
                <div className="flex gap-6">
                  <span>تماس آژانس‌ها:</span>
                  <span>
                    &nbsp; (داخلی
                    {numberConvert(312, {
                      numberOnly: false,
                      locale
                    })}
                    )
                    <a href="tel:75132000" itemScope>
                      {numberConvert(75_132_000, {
                        numberOnly: false,
                        locale
                      })}
                    </a>
                  </span>
                  <span>۰۹۰۴۵۴۷۷۷۹۰</span>
                </div>
                <div className="flex gap-6">
                  <span>تماس شرکت‌ها:</span>
                  <span>
                    &nbsp; (داخلی
                    {numberConvert(305, {
                      numberOnly: false,
                      locale
                    })}
                    )
                    <a href="tel:75132000" itemScope>
                      {numberConvert(75_132_000, {
                        numberOnly: false,
                        locale
                      })}
                    </a>
                  </span>
                  <span>۰۹۲۱۳۹۴۸۲۸۳</span>
                </div>
                <div>{t("footer.contact_us.b2b_email")}</div>
                <div className={styles["contact-us-item"]}>
                  <div className={styles["contact-us-item-key"]}>
                    {t("footer.contact_us.address")}:
                  </div>
                  <div className={styles["contact-us-item-value"]}>
                    {t("footer.contact_us.address_value")}
                  </div>
                </div>
              </>
            )}
          </div>
          <div className={styles["social-media"]}>
            <div className={styles["social-media-title"]}>
              {t("footer.social_media.title")}
            </div>
            <div className={styles["social-media-description"]}>
              {t("footer.social_media.description")}
            </div>
            <div className={styles["social-media-list"]}>
              {(channel === "snapp-ir"
                ? snappSocialMediaLinks
                : socialMediaLinks
              ).map((item) => (
                <Button
                  EndIcon={item.icon}
                  aria-label={item.ariaLabel}
                  className={styles["social-media-item"]}
                  color="neutral"
                  equalDimension
                  href={item.url}
                  key={item.url}
                  rel={item.rel}
                  role="link"
                  size="x-small"
                  target="_blank"
                  variant="text"
                />
              ))}
            </div>
          </div>
        </div>
      </FooterRow>
      {!minimalView && hasDownloadApp && (
        <FooterRow>
          <div className={styles["download-app"]}>
            <div className={styles["download-app-content"]}>
              <div className={styles["download-app-title"]}>
                {t("footer.download_app.title")}
              </div>
            </div>
            <div className={styles["download-app-list"]}>
              {applications.map((item) => (
                <a
                  className={styles["download-app-item"]}
                  href={item?.url}
                  itemScope
                  key={item?.url}
                  rel="noreferrer nofollow"
                  target="_blank"
                >
                  {item?.image && (
                    <LazyImage
                      alt={item?.alt}
                      height={item?.height}
                      loading="lazy"
                      src={item?.image}
                      width={item?.width}
                    />
                  )}
                </a>
              ))}
            </div>
          </div>
        </FooterRow>
      )}
      <FooterRow>
        <div className={styles["about-us"]}>
          <div className={styles["about-us-content"]}>
            <div className={styles["about-us-title"]}>
              {t("footer.about.title")}
            </div>
            <div className={styles["about-us-description"]}>
              {t("footer.about.description")}
            </div>
          </div>
          <div className={styles["about-us-list"]}>
            {certificates.map((item) => (
              <div className={styles["about-us-item"]} itemScope key={item?.id}>
                {item?.src && <LazyImage {...item} />}
              </div>
            ))}
          </div>
        </div>
      </FooterRow>
      {hasLinkBadge && (
        <FooterRow>
          <div className={styles["badgs"]}>
            {linksBadgs?.map((badgs) => (
              <Button
                className={styles["button"]}
                color="neutral"
                href={badgs.url}
                key={badgs.title}
                role="link"
                text={badgs.title}
                variant="round-outline"
              />
            ))}
          </div>
        </FooterRow>
      )}
      <FooterRow background="gray">
        <div className={styles["copyright"]}>
          <div className={styles["copyright-item"]}>
            {t("footer.copyright.title")}
            {channel !== "snapp-ir" && t("footer.copyright.company_name")}
          </div>
          <div className={styles["copyright-item"]}>{copyright.sign}</div>
        </div>
      </FooterRow>
    </footer>
  );
};
