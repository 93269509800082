import { useQuery } from "react-query";
import {
  AirportsService,
  AirportsUrl
} from "../../../../../services/pwa/flights/airports";

export const useAirportsService = (query: string) => {
  const { isLoading, data } = useQuery(
    [AirportsUrl, query],
    () => AirportsService({ query }),
    {
      enabled: query.length >= 3
    }
  );

  return { isLoading, data };
};
