import { Hotel, MaterialIcon } from "@website/icons";
import { Button, Loading, TextField } from "@website/ui-old";
import { FC } from "react";
import { Header } from "..";
import styles from "./b2b-inquiry.module.scss";
import { B2BInquiryPropertiesTypes } from "./types";
import { useB2BInquiry } from "./use-b2b-inquiry";

export const B2BInquiry: FC<B2BInquiryPropertiesTypes> = (properties) => {
  const {
    phoneNumber,
    contractId,
    onChangePhoneNumber,
    onChangeContractId,
    onSubmitPhoneNumber,
    isLoading,
    error
  } = useB2BInquiry(properties);

  return (
    <div className={styles["container"]}>
      <Header title="ورود به پنل سازمانی" />
      <div className={styles["title"]}>
        لطفا برای ورود اطلاعات زیر را وارد کنید.
      </div>
      <form onSubmit={isLoading ? undefined : onSubmitPhoneNumber}>
        <div className={styles["input"]}>
          <TextField
            StartIcon={<Hotel width={18} />}
            error={Boolean(error)}
            fullWidth
            onChange={onChangeContractId}
            placeholder="کد شرکت"
            value={contractId}
          />
          <TextField
            StartIcon={<MaterialIcon name="phone_iphone" />}
            error={Boolean(error)}
            fullWidth
            onChange={onChangePhoneNumber}
            placeholder="شماره موبایل"
            value={phoneNumber}
          />
        </div>
        {error && (
          <div className={styles["info"]}>
            <span className={styles["info-icon"]}>
              <MaterialIcon name="info" variant="outlined" />
            </span>
            {error}
          </div>
        )}
        <div className={styles["footer"]}>
          <Button
            color="secondary"
            fullWidth
            size="large"
            type="submit"
            variant="contained"
          >
            {isLoading ? (
              <Loading color="white" size="lg" />
            ) : (
              "تایید و ورود به اسنپ‌تریپ!"
            )}
          </Button>
        </div>
      </form>
    </div>
  );
};
