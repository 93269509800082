export const profile = {
  hotel: {
    main_search: {
      check_in: "تاریخ ورود",
      check_out: "تاریخ خروج",
      destination: "شهر یا هتل مقصد",
      number_of_adult: "تعداد بزرگسال",
      number_of_child: "تعداد کودک",
      number_of_room: "تعداد اتاق",
      greather_than_n_years: "{age} سال به بالا",
      between_m_and_n_years: "{age_one} تا {age_two} سال",
      number_of_passenger_and_room: "تعداد مسافر و اتاق",
      x_passenger_and_y_room: "{passenger_count} مسافر، {room_count} اتاق"
    },
    suggestions: {
      title: "هتل‌های پیشنهادی",
      subtitle:
        "برای دیدن هتل‌های پیشنهادی هر شهر لطفا مقصد خود را انتخاب کنید",
      zero: "صفر",
      one: "یک",
      two: "دو",
      three: "سه",
      four: "چهار",
      five: "پنج",
      count_stars: "{star} ستاره",
      price_per_night: "قیمت هر شب",
      toman: "تومان"
    },
    popular_cities: {
      title: "محبوبترین شهرهای ایران"
    },
    features: {
      title:
        "اسنپ‌تریپ ! رزرو هتل٬ اقامتگاه و ویلا٬ خرید بلیط هواپیما٬ قطار٬ اتوبوس و انواع تور",
      online_reserve: {
        title: "رزرو کاملا آنلاین",
        description: "رزرو هتل و خرید بلیط راحت و سریع"
      },
      best_price: {
        title: "تضمین بهترین قیمت",
        description: "بیشترین تخفیفها برای رزرو هتل و خرید بلیط"
      },
      support: {
        title: "پشتیبانی ۲۴ ساعته",
        description: "پشتیبانی ۷ روز هفته و روزهای تعطیل"
      },
      safe_choice: {
        title: "امکان بررسی نظرات کاربران",
        description: "بررسی کیفیت هتل ها از دید کاربران"
      }
    },
    special_contents: {
      title: "آنچه باید درباره‌ی رزرو هتل بدانید"
    },
    faq: {
      title: "پاسخ به برخی از سوالات"
    },
    inbound_links: {
      title: "سفر بعدیت رو از الان برنامه ریزی کن"
    }
  },
  general: {
    title: "حساب کاربری",
    gender: "جنسیت",
    male: "مرد",
    female: "زن",
    navbar: {
      username: "نام کاربری",
      passengers_list: "لیست مسافران",
      user_management: "مدیریت کاربران",
      wallet: "کیف پول",
      my_trips: "سفرهای من",
      support: "پشتیبانی",
      logout: "خروج از حساب",
      transactions: "لیست تراکنش ها",
      unit: "تومان"
    },
    driverjs: {
      profile_btn: {
        title: "حساب کاربری",
        description:
          "برای بهبود تجربه‌کاربری در اسنپ تریپ، برای شما حساب کاربری منحصر به فردی ایجاد شده است.این بخش در حال حاضر شامل اطلاعات کاربری و لیست مسافران شما می باشد.",
        done: "متوجه شدم"
      },
      navbar: {
        title_1: "اطلاعات کاربری",
        title_2: "لیست مسافران",
        description_1:
          "در این بخش شما میتوانید اطلاعات کاربری خود شامل نام و نام خانوادگی و موبایل و ایمیل و سایر اطلاعات شخصی را مشاهده کنید.",
        description_2:
          "در این بخش شما میتوانید اطلاعات مسافران خود را وارد کنید و در فرآیند های خرید یا رزرو از اطلاعات آنها استفاده کنید و مراحل را به سهولت پیش ببرید.",
        next: "بعدی",
        previous: "قبلی",
        understand: "متوجه شدم"
      }
    }
  },
  wallet: {
    title: "کیف پول",
    charge_balance_title: "افزایش موجودی",
    charge_balance_description:
      "برای افزایش موجودی کیف پول، مبلغ مورد نظر خود را وارد کنید.",
    unit: "تومان",
    amount: "مبلغ",
    amount_placeholder: "مبلغ مورد نظر",
    wallet_balance: "موجودی کیف پول",
    charge_balance_button: "افزایش موجودی",
    balance_credit: "موجودی و اعتبار",
    withdraw_requests: "درخواست های برداشت",
    withdraw: "برداشت",
    withdraw_empty: "هیچ درخواست برداشتی ندارید.",
    messages: {
      min_value: "حداقل مبلغ قابل پرداخت {amount} تومان است.",
      max_value: "حداکثر مبلغ قابل پرداخت {amount} تومان است.",
      none_zero_start: "مبلغ نباید با صفر آغاز شود.",
      deposit: "خطایی رخ داده است، دوباره تلاش کنید."
    },
    deposit_message: {
      success_title: "پرداخت موفق",
      failed_title: "پرداخت ناموفق",
      charge_success: "اعتبار کیف پول شما {amount} تومان افزایش یافت.",
      charge_failed: "متاسفانه پرداخت شما انجام نشد. لطفا دوباره تلاش کنید.",
      charge_cashback:
        "اگر هزینه از کارت شما کسر شده است، مطابق قوانین بانکی کشور طی ۷۲ ساعت آینده به کارت شما بازگشت داده خواهد شد.",
      tracking_code: "کد پیگیری:"
    },
    withdraw_modal: {
      title: "برداشت از کیف پول",
      continue: "تایید و ادامه",
      accept_and_submit: "تایید و ثبت درخواست",
      back_wallet: "بازگشت به کیف پول",
      step_one_title: "قوانین",
      step_one_list_one:
        "موجودی تنها به کارتی که با آن واریز انجام شده، قابل انتقال است.",
      step_one_list_two:
        "اگر از کارتهای بانکی عضو شبکه شتاب استفاده میکنید؛ در صورت احراز هویت، این مبلغ طی سه روز کاری برای شما واریز میشود.",
      step_one_list_three:
        "در صورتی که اعتبار شما به واسطه کارت هدیه، بن کارت یا هدایای سازمانی، شارژ شده باشد، امکان انتقال مبلغ به حساب بانکی وجود ندارد.",
      withdrawable: "قابل برداشت",
      step_two_title: "کارت بانکی مورد نظر را انتخاب کنید",
      step_three_title: "شماره کارت بانکی خود را تکمیل کنید.",
      step_three_amount_description: "مبلغ مورد نظر را وارد کنید.",
      step_three_amount_label: "مبلغ مورد نظر",
      step_three_amount_placeholder: "مبلغ مورد نظر را وارد کنید.",
      step_three_cashout_all_amount: "برداشت کل موجودی",
      step_three_max_amount_error:
        "مبلغ درخواستی بیشتر از مبلغ قابل برداشت است.",
      step_three_min_amount_error: "حداقل مبلغ برداشت ۱۰۰۰ تومان است.",
      step_four_request_info: "اطلاعات درخواست",
      card_number: "شماره کارت",
      card_owner: "صاحب کارت",
      amount: "مبلغ درخواستی",
      success_title: "درخواست شما با موفقیت ثبت شد.",
      success_description:
        "مبلغ درخواستی در صورت تایید، تا حداکثر ۷۲ ساعت به کارت بانکی شما واریز می‌گردد.",
      success_withdraw: "درخواست برداشت",
      terms_one: " ثبت درخواست به معنای پذیرش",
      terms_two: " قوانین و مقررات برداشت از کیف پول",
      terms_three: " می باشد.",
      failed_submit: "ثبت درخواست ناموفق بود. لطفا مجدد تلاش کنید."
    },
    withdraw_requests_table: {
      date: "تاریخ و ساعت",
      amount: "مبلغ (تومان)",
      cardNumber: "شماره کارت بانکی",
      status: "وضعیت",
      success: "واریز شده",
      progress: "در حال بررسی",
      unknown: "نامشخص",
      see_more: "مشاهده بیشتر"
    },
    terms_modal: {
      title: "قوانین برداشت از کیف پول",
      realized: "متوجه شدم",
      p_1: "قبل از ارسال درخواست بدانید",
      p_2: "برای ثبت درخواست و احراز هویت، لطفا اطلاعات کارت بانکی که با آن واریز انجام شده است را به دقت وارد کنید.",
      p_3: "موجودی تنها به کارتی که با آن واریز انجام شده، قابل انتقال است.",
      p_4: "اگر از کارتهای بانکی عضو شبکه شتاب استفاده میکنید؛ در صورت احراز هویت، این مبلغ طی سه روز کاری برای شما واریز میشود.",
      p_5: "در صورتی که اعتبار شما به واسطه کارت هدیه، بن کارت یا هدایای سازمانی، شارژ شده باشد، امکان انتقال مبلغ به حساب بانکی وجود ندارد.",
      p_6: "در هر روز، شما میتوانید فقط تا سقف 7 میلیون تومان، درخواست انتقال وجه داشته باشید.",
      p_7: "مسئولیت تمام اطلاعات واردشده به عهده شما (کاربر) است و در صورت مشاهده رفتار مشکوک، حساب کاربری شما مسدود خواهد شد.",
      p_8: "اطلاعات خواسته شده برای انتقال وجه از اعتبار به کارت بانکی، از پروفایل کاربری شما گرفته شده است. قصد ما، احراز هویت و حفظ امنیت حساب شماست. درصورتیکه این اطلاعات کامل و دقیق نیست، میتوانید هنگام درخواست انتقال وجه آنها را ویرایش کنید.",
      p_9: "شما فقط امکان انتقال وجه به حساب بانکی و شماره کارتی را دارید که با شماره همراه متصل به آن حساب، در اسنپ‌تریپ ثبت نام کرده اید.",
      p_10: "مدت زمان انجام تسویه حساب و انتقال وجه، حداکثر تا 72 ساعت است."
    }
  },
  transactions: {
    title: "لیست تراکنش ها",
    download_report: "دانلود گزارش",
    empty_title: "تراکنش مالی یافت نشد.",
    empty_description: "رزرو یا خریدی از اسنپ‌تریپ نداشته اید.",
    date: "تاریخ و ساعت",
    amount: "مبلغ (تومان)",
    type: "نوع تراکنش",
    gateway: "درگاه",
    description: "توضیحات",
    withdraw_title: "خرید",
    deposit_title: "افزایش اعتبار",
    see_more: "مشاهده بیشتر",
    unit: "تومان"
  },
  information: {
    title: "اطلاعات کاربر",
    submit: "ذخیره اطلاعات",
    basic_information_section: {
      title: "اطلاعات اصلی",
      first_and_last_name: "نام و نام خانوادگی",
      phone_number: "شماره موبایل",
      email: "ایمیل"
    },
    password_section: {
      title: "رمز عبور",
      description:
        "برای ورود آسان‌ و امن‌ به حساب کاربری از رمز عبور استفاده کنید.",
      add: "افزودن رمز عبور",
      edit: "ویرایش رمز عبور"
    },
    secondary_information_section: {
      title: "اطلاعات فرعی",
      national_code: "کد ملی",
      birth_date: "تاریخ تولد",
      gender: "جنسیت"
    },
    agency_section: {
      title: "اطلاعات آژانس",
      code: "کد آژانس",
      name: "نام آژانس"
    },
    corporate_section: {
      title: "اطلاعات شرکت",
      code: "کد شرکت",
      name: "نام شرکت"
    },
    bank_information_section: {
      title: "اطلاعات بانکی",
      account_number: "شماره حساب",
      card_number: "شماره کارت",
      iban: "شماره شبا"
    },
    delete_account_section: {
      title: "حذف حساب کاربری",
      description:
        "حذف حساب یک اقدام بدون بازگشت می باشد و پس از آن تمام اطلاعات حسابتان حذف میشود.",
      caution:
        "لطفا قبل از حذف حساب، از خالی بودن موجودی کیف پولتان اطمینان حاصل کنید.",
      delete: "حذف حساب"
    },
    change_password_flow: {
      add_card: {
        title: "افزودن رمز عبور",
        description:
          "برای ورود آسان و امن به حساب کاربری، رمز عبور دلخواه خود را وارد کنید.",
        name: "رمز عبور",
        accept: "تایید و دخیره",
        cancel: "انصراف"
      },
      edit_card: {
        title: "ویرایش رمز عبور",
        description:
          "برای ورود آسان و امن به حساب کاربری، رمز عبور دلخواه خود را وارد کنید.",
        new_password: "رمز عبور جدید",
        old_password: "رمز عبور فعلی",
        accept: "تایید و دخیره",
        cancel: "انصراف"
      },
      otp_card: {
        title: "وارد کردن کد تایید",
        description: "کد تایید را به شماره {phoneNumber} فرستادیم.",
        accept: "تایید و ادامه",
        cancel: "انصراف"
      }
    },
    password_card: {
      title: "افزودن رمز عبور",
      description:
        "برای ورود آسان و امن به حساب کاربری، رمز عبور دلخواه خود را وارد کنید.",
      name: "رمز عبور",
      accept: "تایید و دخیره",
      cancel: "انصراف"
    },
    submit_card: {
      title: "ذخیره تغییرات",
      description: "تغییراتی که اعمال کرده اید ذخیره نشده است.",
      description_part_2: "آیا از خروج از صفحه مطمین هستید؟",
      accept: "ذخیره و خروج",
      cancel: "خروج"
    },
    delete_account_card: {
      title: "حذف حساب کاربری",
      description:
        "حذف حساب یک اقدام بدون بازگشت می باشد و پس از آن تمام اطلاعات حسابتان (لیست مسافران، تاریخچه سفرها، تخفیف ها، تراکنش ها) در تمام پلتفرم های اسنپ‌تریپ از جمله وبسایت و اپلیکیشن و سوپر اپلیکیشن اسنپ حذف میشود.",
      sure: "از حذف حساب خود اطمینان دارم.",
      accept: "حذف حساب",
      cancel: "انصراف"
    },
    delete_account_otp_card: {
      title: "وارد کردن کد تایید",
      description: "کد تایید را به شماره {phoneNumber} فرستادیم.",
      wrong_mobile_number: "شماره موبایل اشتباه است؟",
      edit: "ویرایش",
      did_not_receive_otp: "کد تایید را دریافت نکردم",
      send_again: "ارسال دوباره",
      accept: "تایید و حذف حساب",
      cancel: "انصراف"
    },
    messages: {
      saved: "اطلاعات شما با موفقیت ذخیره شد.",
      general_error: "خطایی رخ داده است, دوباره تلاش کنید.",
      info_not_changed: "اطلاعات تغییری نکرده است."
    }
  },
  passengers_list: {
    title: "لیست مسافران",
    add_passenger: "افزودن مسافر",
    edit_passenger: "ویرایش مسافر",
    no_passenger: {
      title: " هنوز مسافری افزوده نشده",
      description:
        "با افزودن مسافران در این بخش، فرایند خرید آسان تری خواهید داشت و میتوانید مسافر را به آسانی از این لیست انتخاب کنید.",
      driverjs: {
        intro_title: "افزودن مسافر",
        intro_description:
          "برای تسهیل فرآیند های خرید یا رزرو داشتن تجربه ای بهتر ، لیستی از مسافران خود را ایجاد کنید و هنگام خرید یا رزرو، مسافران را از این لیست به راحتی انتخاب کنید.",
        understand: "متوجه شدم"
      }
    },
    no_passenger_found: {
      title: "نتیجه‌ای یافت نشد.",
      descriptionـline_1_part_1: "مسافری با مشخصات",
      descriptionـline_1_part_2: "یافت نشد",
      description_line_2: "با کلمه ی دیگری جستجو کنید."
    },
    passenger_table: {
      status: "وضعیت",
      passport_number: "شماره گذرنامه",
      national_code: "کد ملی",
      birth_date: "تاریخ تولد",
      gender: {
        title: "جنسیت",
        male: "مرد",
        female: "زن",
        other: "نامشخص"
      },
      name: "نام و نام خانوادگی",
      passrport_was_expired: "گذرنامه منقضی شده",
      passport_expire_less_than_six_months: "انقضا کمتر از ۶ ماه"
    },
    passenger_search: {
      placeholder: "جستجو کد ملی و شماره گذرنامه"
    },
    add_passenger_modal: {
      general_info: "اطلاعات عمومی",
      general_info_warning:
        "اطلاعات مسافران باید کاملا منطبق با مدارک شناسایی (کارت ملی، پاسپورت)‌ باشد.",
      persain_firstname: "نام (به فارسی)*",
      persain_lastname: "نام خانوادگی (به فارسی)*",
      gender: "جنسیت*",
      jalaali_birthday: "تاریخ تولد به شمسی*",
      national_code: "کد ملی",
      national_code_desceription:
        "کد ملی برای مسافرین ایرانی که قصد سفرهای داخل ایران را دارند مورد نیاز است.",
      passport_info: "اطلاعات پاسپورت",
      passport_desceription:
        "اطلاعات پاسپورت برای مسافرین ایرانی که قصد سفر های خارجی را دارند و همچنین مسافرین غیرایرانی که قصد استفاده از هر نوع خدماتی را داشته باشند مورد نیاز است.",
      first_name_en: "نام (به انگلیسی)",
      last_name_en: "نام خانوادگی (به انگلیسی)",
      birth_country: "کشور محل تولد",
      garegorian_birthday: "تاریخ تولد میلادی",
      passport_number: "شماره گذرنامه",
      passport_issuing_place: "کشور صادرکننده گذرنامه",
      passport_expiration_date: "تاریخ انقضای گذرنامه",
      passport_expiration_error: "انقضا کمتر از ۶ ماه",
      passport_expiration_warning:
        "برای سفر های خارجی، باید از تاریخ انقضای پاسپورت بیشتر از ۶ ماه مانده باشد."
    },
    btns: {
      save_desceription: "ذخیره اطلاعات",
      cancel: "انصراف"
    },
    status: {
      incomplete: "ناقص",
      complete: "تکمیل",
      needtoedit: "نیاز به ویرایش",
      notassigned: "نامشخص"
    },
    mobile_table: {
      see_more: "مشاهده بیشتر"
    },
    delete_confirm: {
      title: "حذف مسافر",
      description_line1: "با حذف مسافر، تمام اطلاعات مسافر حذف خواهد شد.",
      description_line2_sec_1: "آیا از حذف ",
      description_line2_sec_2: " اطمینان دارید؟",
      submit: "حذف مسافر",
      cancel: "انصراف"
    },
    bottom_sheet: {
      edit: "ویرایش اطلاعات",
      delete: "حذف مسافر"
    },
    messages: {
      general_error: "خطایی رخ داده است, دوباره تلاش کنید.",
      passenger_was_added: "مسافر با موفقیت افزوده شد.",
      passenger_info_saved: "اطلاعات مسافر با موفقیت ذخیره شد.",
      passenger_deleted: "مسافر از لیست حذف شد."
    }
  }
};
