const commonPaths = new Set([
  "/",
  "/bus",
  "/bus-international",
  "/flights",
  "/tour",
  "/villa",
  "/international-hotel",
  "/home-visa"
]);

export const pathsHasAuth = new Set([
  ...commonPaths,
  "/inter-flights",
  "/policy",
  "/about",
  "/contact"
]);
export const pathsHasSearch = new Set([
  ...commonPaths,
  ...(process.env.NEXT_PUBLIC_CHANNEL_TYPE === "jek"
    ? ["/train", "/interflights"]
    : ["/inter-flights"])
]);
