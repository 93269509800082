import { parseDateTime } from "@internationalized/date";
import { useLocalStorage } from "@website/hooks";
import { useTranslation } from "@website/locale";
import { addEventToTracking, USER_TRACKING_EVENTS } from "@website/tracking";
import { DatepickerSelectedDateType } from "@website/ui-old";
import { useRouter } from "next/router";
import { useCallback } from "react";
import { SubmitSearchFlightsPropertiesType } from "../search-flights.types";

export const useSubmit = (
  originItem: any,
  destinationItem: any,
  saleType: string,
  date: DatepickerSelectedDateType,
  calendarLocale: string,
  passengerNumber: { adult: number; child: number; infant: number },
  flightClass: string,
  addToHistory: (
    origin: any,
    destination: any,
    startDate: any,
    endDate: any,
    calendarLocale: string,
    saleType: any,
    passengerNumber: any
  ) => void,
  submitValidationCheck: () => boolean,
  properties: SubmitSearchFlightsPropertiesType
) => {
  const { t } = useTranslation();
  const { getFromLocalStorage } = useLocalStorage();
  const router = useRouter();
  const channel = process.env.NEXT_PUBLIC_CHANNEL_TYPE;
  const { snappTripToken } = properties;
  const snappToken = getFromLocalStorage("snappTripToken");
  const onSubmitClick = useCallback(() => {
    if (submitValidationCheck()) {
      let startDateTime = "";
      let endDateTime = "";
      let startDateObject = null;
      let endDateObject = null;
      let startDate = null;
      let endDate = null;
      if (saleType === "round-trip" && date && date[0] && date[1]) {
        startDateTime = date[0].dateTime;
        endDateTime = date[1].dateTime;

        startDateObject = parseDateTime(startDateTime);
        endDateObject = parseDateTime(endDateTime);

        startDate = `${startDateObject.year}-${startDateObject.month
          .toString()
          .padStart(2, "0")}-${startDateObject.day
          .toString()
          .padStart(2, "0")}`;
        endDate = `${endDateObject.year}-${endDateObject.month
          .toString()
          .padStart(2, "0")}-${endDateObject.day.toString().padStart(2, "0")}`;
      } else if (saleType === "one-way" && date && date[0]) {
        startDateTime = date[0].dateTime;

        startDateObject = parseDateTime(startDateTime);

        startDate = `${startDateObject.year}-${startDateObject.month
          .toString()
          .padStart(2, "0")}-${startDateObject.day
          .toString()
          .padStart(2, "0")}`;
      }

      if (
        originItem &&
        destinationItem &&
        ((startDate && saleType === "one-way") ||
          (endDate && saleType === "round-trip"))
      ) {
        addToHistory(
          originItem,
          destinationItem,
          startDateTime,
          endDateTime,
          calendarLocale,
          saleType,
          passengerNumber
        );

        const parameters = {
          adultCount: `${passengerNumber.adult || 1}`,
          childCount: `${passengerNumber.child || 0}`,
          infantCount: `${passengerNumber.infant || 0}`,

          // remove after redesign
          adults: `${passengerNumber.adult || 1}`,
          children: `${passengerNumber.child || 0}`,
          infants: `${passengerNumber.infant || 0}`,
          departDate: startDate || "",
          authToken: snappToken,
          origin: originItem?.iataCode,
          origin_city: originItem.cityFaName,
          destination: destinationItem?.iataCode,
          destination_city: destinationItem?.cityFaName,
          roundTrip: saleType === "round-trip" ? "true" : "false",
          // end

          dateType:
            calendarLocale === "persian"
              ? "jalali"
              : calendarLocale === "gregorian"
                ? "miladi"
                : calendarLocale,
          departureDate: startDate || "",
          tripType: saleType === "round-trip" ? "roundtrip" : "oneway",
          ...(endDate && { returnDate: endDate }),
          source: "searchBox",
          channel,
          originCity: originItem?.cityName?.replaceAll(" ", "-"),
          originCityName: originItem?.cityFaName,
          originCode: originItem?.iataCode,
          destinationCity: destinationItem?.cityName?.replaceAll(" ", "-"),
          destinationCityName: destinationItem?.cityFaName,
          destinationCode: destinationItem?.iataCode,
          cabinType: "ECONOMY",
          date: startDate,
          refer: "flights-home",
          ...(channel === "jek" ? { ...router.query } : {})
        };
        window?.webengage?.track("flight-search-clicked", {
          ...parameters,
          channel,
          source: "searchBox"
        });
        window?.dataLayer?.push({
          event: "flights-search",
          ecommerce: {
            items: [
              {
                ...router?.query,
                origin_city: originItem.cityName,
                destination_city: destinationItem.cityName,
                departDate: startDate || "",
                adults: `${passengerNumber.adult}`,
                children: `${passengerNumber.child}`,
                infants: `${passengerNumber.infant}`,
                ...(endDate && { returnDate: endDate }),
                authToken: snappTripToken,
                source: "searchBox"
              }
            ]
          }
        });

        const searchWindow = () => {
          try {
            return Math.round(
              Math.abs(
                new Date(startDate || "").getTime() / 86_400_000 -
                  Date.now() / 86_400_000
              )
            );
          } catch {
            return 0;
          }
        };
        addEventToTracking(USER_TRACKING_EVENTS.GENERAL_SEARCH, {
          product: "dom-flight",
          departure_date: startDate ? new Date(startDate)?.toISOString() : "",
          return_departure_date: endDate
            ? new Date(endDate)?.toISOString()
            : "",
          cabin_type: flightClass,
          origin_city: originItem?.cityName,
          destination_city: destinationItem?.cityName,
          origin_is_city: !originItem?.airportIataCode,
          destination_is_city: !destinationItem?.airportIataCode,
          origin_airport: originItem?.airportIataCode
            ? originItem?.airportName
            : "All Airports",
          destination_airport: destinationItem?.airportIataCode
            ? destinationItem?.airportName
            : "All Airports",
          search_calendar_type: calendarLocale,
          trip_type: saleType,
          number_of_passengers:
            Number(passengerNumber?.adult) ||
            0 + Number(passengerNumber?.child) ||
            0 + Number(passengerNumber?.infant) ||
            0,
          route: `${originItem?.cityName - destinationItem?.cityName}`,
          search_source: "search-box",
          search_window: searchWindow()
        });

        const urlParameters = new URLSearchParams(parameters);
        window.location.href = `${
          process.env.NEXT_PUBLIC_BASE_URL_FLIGHT_INTERNATIONAL_RESULT
        }/api/listing/v1/redirect/search?${urlParameters.toString()}`;
      }
    }
  }, [
    submitValidationCheck,
    saleType,
    date,
    originItem,
    destinationItem,
    addToHistory,
    calendarLocale,
    passengerNumber,
    snappToken,
    channel,
    router.query,
    snappTripToken,
    flightClass
  ]);

  return {
    onSubmitClick,
    submitText: t("action.search")
  };
};
