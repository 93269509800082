import { MaterialIcon } from "@website/icons";
import { useTranslation } from "@website/locale";
import { Modal } from "@website/ui";
import { deepCompare } from "@website/utils";
import { memo, useState } from "react";
import { ReserveCommentPopupScroll } from "../../reserve-comment-popup-scroll";
import { ReserveCommentPopupPropertiesTypes } from "./reserve-comment-popup.types";

const Component = ({
  rate,
  comments,
  onClose,
  open,
  onLoadMore,
  isLoading,
  onLoadFilter,
  filters
}: ReserveCommentPopupPropertiesTypes) => {
  const [showShadow, setShowShadow] = useState(false);
  const { t } = useTranslation();
  return (
    <Modal
      className="z-40 !inline bg-white !p-0 !transition-none"
      fullWidth
      onClose={onClose.bind(null, false)}
      open={open}
    >
      <div
        className={`border-b-outline-thin flex h-[64px] w-full flex-row items-center justify-start gap-4 border bg-white px-6 2xl:px-[calc((100%-1200px)/2)] ${
          showShadow ? "shadow-4" : ""
        }`}
      >
        <MaterialIcon
          className="text-on-surface-medium-emphasis scale-150 cursor-pointer"
          name="arrow_forward"
          onClick={onClose.bind(null, false)}
        />
        <span className="text-subtitle-1">
          {t("comments.passengers_comments_for_residence", {
            residence: rate?.hotelType
          })}
        </span>
      </div>
      <ReserveCommentPopupScroll
        comments={comments}
        filters={filters}
        isLoading={isLoading}
        onLoadFilter={onLoadFilter}
        onLoadMore={onLoadMore}
        rate={rate}
        setShowShadow={setShowShadow}
      />
    </Modal>
  );
};
export const ReserveCommentPopup = memo(Component, deepCompare);
