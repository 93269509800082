import { useIsVisible } from "@website/hooks";
import { useRouter } from "next/router";
import { useCallback, useEffect, useState } from "react";

const useSearchWrapper = () => {
  const key = "OPEN_SEARCH";
  const router = useRouter();
  const isKey = router?.query?.[key];
  const [isSearchContainerScrolled, setIsSearchContainerScrolled] =
    useState(false);

  const { reference: scrolledReference } = useIsVisible({
    onChange: (inView) => setIsSearchContainerScrolled(!inView)
  });

  const addItemToQueryString = useCallback(
    (key: string, value: string) => {
      const { pathname, query } = router;
      const newQuery = { ...query, [key]: value };
      router.push({ pathname, query: newQuery }, undefined, {
        shallow: true
      });
    },
    [router]
  );

  const removeItemFromQueryString = useCallback(
    (key: string) => {
      const { pathname, query } = router;
      const newQuery = { ...query };
      delete newQuery[key];
      router.push({ pathname, query: newQuery }, undefined, {
        shallow: true
      });
    },
    [router]
  );

  useEffect(() => {
    document.body.style.overflowY = isKey ? "hidden" : "auto";
  }, [isKey]);

  const handleClose = useCallback(() => {
    removeItemFromQueryString(key);
  }, [removeItemFromQueryString]);

  const handleOpen = useCallback(() => {
    addItemToQueryString(key, "true");
  }, [addItemToQueryString]);

  return {
    isSearchPanelOpen: Boolean(isKey),
    handleClose,
    handleOpen,
    scrolledReference,
    isSearchContainerScrolled
  };
};

export default useSearchWrapper;
