import {
  FC,
  createContext,
  useCallback,
  useContext,
  useMemo,
  useState
} from "react";
import { AlertSeverityType, AlertSizeType } from "..";
import { SnackbarItem } from "./components";
import styles from "./snackbar.module.scss";
import {
  SnackbarContextType,
  SnackbarItemType,
  SnackbarProviderPropertiesType
} from "./snackbar.types";

export const SnackbarContext = createContext<SnackbarContextType>({
  enqueue: () => null
});

export const SnackbarProvider: FC<SnackbarProviderPropertiesType> = (
  properties
) => {
  const { children, queueSize = 1, duration = "short" } = properties;
  const [queue, setQueue] = useState<Array<SnackbarItemType>>([]);

  const enqueue = useCallback(
    (
      title: string,
      severity?: AlertSeverityType,
      size?: AlertSizeType,
      Action?: JSX.Element
    ) => {
      const id = title + Date.now();

      setQueue((state) => [
        ...state.filter((_, index) => state.length < queueSize || index !== 0),
        {
          id,
          title,
          severity,
          size,
          Action
        }
      ]);

      return id;
    },
    [queueSize]
  );

  const dequeue = useCallback((id: string) => {
    setQueue((state) => state.filter((item) => item.id !== id));
  }, []);

  const value = useMemo(
    () => ({
      enqueue,
      dequeue
    }),
    [dequeue, enqueue]
  );

  return (
    <SnackbarContext.Provider value={value}>
      {children}
      <div className={styles["container"]}>
        {queue.map((item, index) => (
          <SnackbarItem
            Action={item.Action}
            dequeue={dequeue}
            duration={duration}
            id={item.id}
            index={index}
            key={item.id}
            severity={item.severity}
            size={item.size}
            title={item.title}
          />
        ))}
      </div>
    </SnackbarContext.Provider>
  );
};

export const useSnackbar = () => {
  const context = useContext(SnackbarContext);
  if (context === undefined) {
    throw new Error("useSnackbar must be used within a SnackbarProvider");
  }
  return context;
};
