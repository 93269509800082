import { useRouter } from "next/router";
import { FC } from "react";
import { FooterComponent } from "./footer.component";
import { FooterPropertiesType } from "./footer.types";

export const Footer: FC<FooterPropertiesType> = (properties) => {
  const router = useRouter();

  if (
    ["android", "ios", "jek", "pwa", "ap"].some((channel) =>
      [router.query.channel, process.env.NEXT_PUBLIC_CHANNEL_TYPE].includes(
        channel
      )
    )
  ) {
    return <div className="h-12" />;
  }

  return <FooterComponent {...properties} />;
};
