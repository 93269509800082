import { Footer } from "@website/widget";
import { FC, memo } from "react";
import { HeaderWrapper, Search } from "..";
import { useLayout } from "./layout.hook";
import { LayoutPropertiesType } from "./layout.types";

const Layout: FC<LayoutPropertiesType> = (properties) => {
  const { children } = properties;
  const { headerProperties, snappTripToken, showSearch } = useLayout();

  return (
    <>
      <HeaderWrapper {...headerProperties} />
      <section>
        {showSearch && <Search snappTripToken={snappTripToken} />}
        {children}
      </section>
      <Footer />
    </>
  );
};

const LayoutMemoized = memo(Layout);
export { LayoutMemoized as Layout };
