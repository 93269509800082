import { useCallback, useMemo, useState } from "react";
import { Pagination } from "../pagination";
import { DEFAULT_PAGE_SIZE } from "./table.constant";
import { IColumn, IPagination, ITable } from "./table.types";

export const Table = <T extends Record<string, any>>({
  data: originalData = [],
  columns,
  rowKey = "id",
  pagination,
  onChange = () => {}
}: ITable<T>) => {
  const [sortKey, setSortKey] = useState<string | null>(null);
  const [sortOrder, setSortOrder] = useState<"asc" | "desc" | null>(null);

  const getPaginationValue = useCallback(
    (propertyName: keyof IPagination, defaultValue: number) =>
      typeof pagination === "object" && pagination[propertyName]
        ? pagination[propertyName]
        : defaultValue,
    [pagination]
  );

  const [current, setCurrent] = useState(getPaginationValue("current", 1));
  const { pageSize, total, paginationEnabled, internalPaginationEnabled } =
    useMemo(() => {
      const pageSize = getPaginationValue("pageSize", DEFAULT_PAGE_SIZE);
      const total = getPaginationValue("total", originalData?.length);
      const paginationEnabled =
        typeof pagination === "object" &&
        pagination.total > pagination.pageSize;
      const internalPaginationEnabled =
        typeof pagination === "object" &&
        pagination.total > pagination.pageSize &&
        !(pagination.current || pagination.total);

      return { pageSize, total, paginationEnabled, internalPaginationEnabled };
    }, [pagination, originalData?.length, getPaginationValue]);

  const records = useMemo(() => {
    let result = originalData;

    if (sortKey) {
      const { sorter } = columns.find((col) => col.key === sortKey);
      if (typeof sorter === "function") {
        result = [...originalData].sort((a, b) =>
          sortOrder === "asc" ? sorter(a, b) : -sorter(a, b)
        );
      }
    }

    if (internalPaginationEnabled) {
      result = result?.slice((current - 1) * pageSize, current * pageSize);
    }

    return result;
  }, [
    columns,
    originalData,
    internalPaginationEnabled,
    sortKey,
    sortOrder,
    current,
    pageSize
  ]);

  const handleSort = (column: IColumn<T>) => {
    const { key } = column;

    const newOrder =
      sortKey === key ? (sortOrder === "asc" ? "desc" : "asc") : "asc";
    setSortOrder(newOrder);
    setSortKey(key);
    onChange({ current, pageSize }, { key, order: newOrder });
  };

  const handlePageChange = (newPage: number) => {
    setCurrent(newPage);
    onChange(
      { current: newPage, pageSize: pageSize },
      { key: sortKey, order: sortOrder }
    );
  };

  return (
    <div className="w-full overflow-x-auto" dir="rtl">
      <table className="w-full min-w-full table-auto border-collapse whitespace-nowrap text-right">
        <thead>
          <tr className="bg-outline-thick">
            {columns.map((column) => (
              <th
                className={`text-gray border-b border-gray-300 px-6 py-3 text-sm font-medium leading-6 text-opacity-[87%] ${
                  column.sorter ? "cursor-pointer" : ""
                } ${column?.className || ""}`}
                key={column.key}
                onClick={() => column.sorter && handleSort(column)}
              >
                {column.title}
                {sortKey === column.key && (
                  <span>{sortOrder === "asc" ? " 🔼" : " 🔽"}</span>
                )}
              </th>
            ))}
          </tr>
        </thead>
        <tbody>
          {records?.map((record) => (
            <tr className="hover:bg-gray-50" key={record[rowKey]}>
              {columns.map((column) => (
                <td
                  className="text-gray border-b border-gray-200 px-6 py-4 text-opacity-[87%]"
                  key={column.key}
                >
                  {column.render
                    ? column.render(record[column.key], record)
                    : record[column.key]}
                </td>
              ))}
            </tr>
          ))}
        </tbody>
      </table>

      {paginationEnabled && (
        <div className="flex w-full justify-center py-6">
          <Pagination
            color="secondary"
            count={Math.ceil(total / pageSize)}
            onChange={handlePageChange}
            page={current}
          />
        </div>
      )}
    </div>
  );
};
