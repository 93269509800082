import { MaterialIcon } from "@website/icons";
import { Button, MiniPopover, RadioGroup } from "@website/ui";
import { deepCompare } from "@website/utils";
import { omit } from "lodash";
import { useRouter } from "next/router";
import { memo } from "react";
import { ReserveCommentFilterItemTypes } from "./reserve-comment-filter-item.types";

const Component = ({
  startIconName,
  filterName,
  radioItems,
  onLoadFilter,
  isInsideDetail
}: ReserveCommentFilterItemTypes) => {
  const router = useRouter();
  return (
    <div className="relative">
      <Button
        EndIcon={<MaterialIcon name="arrow_drop_down" />}
        StartIcon={
          <MaterialIcon
            className={`${
              router?.query?.[`filter_${filterName?.en}`]
                ? "text-secondary"
                : "text-on-surface-medium-emphasis"
            } text-body-1`}
            name={startIconName}
          />
        }
        color={
          router?.query?.[`filter_${filterName?.en}`] ? "secondary" : "neutral"
        }
        onClick={() =>
          router.push(
            {
              pathname: router.pathname,
              query: { ...router.query, popover: `filter_${filterName?.en}` }
            },
            undefined,
            { shallow: true, scroll: false }
          )
        }
        size="small"
        text={filterName?.fa}
        variant="round-outline"
      />
      <MiniPopover
        animate="bottom"
        className="right-0"
        defaultHorizontalPosition="end"
        onClose={() =>
          router.push(
            {
              pathname: router.pathname,
              query: omit(router?.query, "popover")
            },
            undefined,
            { shallow: true, scroll: false }
          )
        }
        open={router.query?.["popover"] === `filter_${filterName?.en}`}
        title={filterName?.fa}
      >
        <RadioGroup
          className="text-subtitle-2 flex-col px-6 py-4"
          color="secondary"
          items={radioItems}
          name={filterName?.en}
          onChange={(event) => {
            router.push(
              {
                pathname: router.pathname,
                query: {
                  ...omit(router?.query, "popover"),
                  [`filter_${filterName?.en}`]: event.target?.value
                }
              },
              undefined,
              { shallow: true, scroll: false }
            );
            return onLoadFilter
              ? onLoadFilter(
                  { [filterName?.en]: String(event.target?.value) },
                  isInsideDetail
                )
              : null;
          }}
          value={router?.query?.[`filter_${filterName?.en}`]}
        />
      </MiniPopover>
    </div>
  );
};
export const ReserveCommentsFilterItem = memo(Component, deepCompare);
