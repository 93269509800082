import { MaterialIcon } from "@website/icons";
import { Button } from "@website/ui";
import { deepCompare, numberConvert, numberSeparator } from "@website/utils";
import { FC, memo, useState } from "react";
import { CheckoutSummaryWrapperPropertiesType } from "./types";

const CheckoutSummaryWrapper: FC<CheckoutSummaryWrapperPropertiesType> = (
  properties
) => {
  const {
    price,
    priceDetails,
    finalPrice,
    trackingCode,
    buttonLoading,
    onPay,
    children,
    buttonTitle = "تایید و پرداخت",
    priceTitle = "قیمت",
    discount,
    finalTitle = "قیمت قابل پرداخت"
  } = properties;
  const [disableDetails, setDisableDetails] = useState(false);

  return (
    <div className="md:border-outline-thin bg-background p-6 md:rounded-xl md:border">
      {Boolean(trackingCode) && (
        <div className="lg:pb-6">
          <div className="text-subtitle-2 text-on-surface-high-emphasis bg-dim-background border-outline-thin flex items-center justify-between rounded-lg border px-3 py-2">
            <div className="pe-4">کد پیگیری</div>
            <div> {trackingCode}</div>
          </div>
        </div>
      )}
      {children}
      <div
        className={`bg-white transition-all max-lg:fixed max-lg:bottom-0 max-lg:right-0 max-lg:z-50 max-lg:w-screen max-lg:px-6 ${
          disableDetails
            ? "max-lg:shadow-24"
            : "max-lg:border-t-outline-thin max-lg:border-t"
        }`}
      >
        <div
          className={`overflow-hidden transition-all duration-300 ${
            disableDetails
              ? "max-lg:max-h-[100rem] max-lg:opacity-100"
              : "max-lg:max-h-0 max-lg:opacity-0"
          }`}
        >
          <div className="text-on-surface-high-emphasis text-subtitle-1 pb-2 pt-6 lg:hidden">
            جزییات قیمت
          </div>
          {priceTitle && (
            <div className="text-body-2 text-on-surface-medium-emphasis flex items-center py-2 font-light">
              <div>{priceTitle} </div>
              <div className="border-b-outline-thin mx-2 flex-1 border-b border-dashed" />
              <div>
                {numberConvert(numberSeparator((price / 10).toFixed(0)), {
                  locale: "fa"
                })}{" "}
                تومان
              </div>
            </div>
          )}
          {priceDetails &&
            priceDetails.map((item) => (
              <div
                className="text-body-2 text-on-surface-medium-emphasis flex items-center py-2 font-light"
                key={item.title}
              >
                <div className="line-clamp-1">{item.title} </div>
                <div className="border-b-outline-thin mx-2 flex-1 border-b border-dashed" />
                <div>
                  {numberConvert(
                    numberSeparator((item.price / 10).toFixed(0)),
                    {
                      locale: "fa"
                    }
                  )}{" "}
                  تومان
                </div>
              </div>
            ))}
          {discount && (
            <div className="text-body-2 text-success flex items-center py-2 font-light">
              <div>کد تخفیف</div>
              <div className="border-b-outline-thin mx-2 flex-1 border-b border-dashed" />
              <div>
                {numberConvert(numberSeparator((discount / 10).toFixed(0)), {
                  locale: "fa"
                })}{" "}
                تومان
              </div>
            </div>
          )}
        </div>
        <div className="justify-between max-lg:flex max-md:flex-col">
          <div className="text-on-surface-high-emphasis flex-wrap items-center justify-between py-4 max-md:flex max-md:items-center lg:flex lg:pb-0">
            <div className="flex items-center justify-between max-md:order-2">
              <div className="text-caption lg:text-subtitle-1 max-lg:py-1 max-lg:pe-4 max-md:hidden">
                {finalTitle}
              </div>
              <button
                className="text-subtitle-2 text-secondary lg:hidden"
                onClick={() => setDisableDetails((previous) => !previous)}
                type="button"
              >
                {disableDetails ? "بستن" : "جزییات قیمت"}
                <MaterialIcon
                  className={`me-1 text-xl transition-all ${
                    disableDetails ? "-rotate-90" : "rotate-90"
                  }`}
                  name="chevron_left"
                />
              </button>
            </div>
            <div className="text-subtitle-1 max-md:order-1">
              {numberConvert(numberSeparator((finalPrice / 10).toFixed(0)), {
                locale: "fa"
              })}{" "}
              تومان
            </div>
          </div>
          {onPay && (
            <Button
              className="max-md:mb-6 md:mt-5 max-lg:md:!w-48"
              fullWidth
              loading={buttonLoading}
              onClick={onPay}
              size="large"
            >
              {buttonTitle}
              <MaterialIcon
                className="me-2 text-2xl max-md:hidden"
                name="chevron_left"
              />
            </Button>
          )}
        </div>
      </div>
    </div>
  );
};

export default memo(CheckoutSummaryWrapper, deepCompare);
