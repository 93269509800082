import { useViewPort } from "@website/hooks";
import { MaterialIcon } from "@website/icons";
import { useTranslation } from "@website/locale";
import { AutoComplete, Button, Datepicker } from "@website/ui-old";
import { FC } from "react";
import {
  useDate,
  useDestination,
  useHistory,
  useSubmit,
  useValidation
} from "./hooks";
import styles from "./search-hotel.module.scss";
import { SearchHotelPropertiesType } from "./search-hotel.types";

const SearchHotel: FC<SearchHotelPropertiesType> = () => {
  const { t } = useTranslation();
  const {
    selectedDestinationItem,
    selectedDestinationText,
    destinationQuery,
    onChangeDestination,
    onChangeDestinationQuery,
    onSelectDestination,
    destinationLoading,
    destinationListNormalized,
    DestinationNoResult
  } = useDestination();
  const { date, calendarLocale, onChangeDate } = useDate();
  const { destinationHistoryList, addToHistory, onSelectDestinationHistory } =
    useHistory(onChangeDestination);
  const { destinationError, dateError, submitValidationCheck } = useValidation({
    destinationItem: selectedDestinationItem,
    date
  });
  const { onSubmitClick } = useSubmit(
    selectedDestinationItem,
    date,
    calendarLocale,
    addToHistory,
    submitValidationCheck
  );
  const { isMobile } = useViewPort();
  // const { toggleList, toggleValue } = useToggle();
  // const channel = process.env.NEXT_PUBLIC_CHANNEL_TYPE;

  return (
    <div className={styles["container"]}>
      {/* <div className={styles["options"]}>
        {!["ads", "snapp-ir", "b2b"].includes(channel) && (
          <ToggleButton
            color="secondary"
            list={toggleList}
            size="small"
            value={toggleValue}
            variant="contained"
          />
        )}
      </div> */}
      <div className={styles["main"]}>
        <div className={styles["inputs-wrapper"]}>
          <div className={styles["autocomplete-wrapper"]}>
            <AutoComplete
              error={destinationError}
              fullWidth
              parts={[
                {
                  StartIcon: <MaterialIcon name="place" />,
                  label: t("homepage.hotel.main_search.destination"),
                  list: destinationListNormalized,
                  historyList:
                    destinationQuery.length <= 1 ? destinationHistoryList : [],
                  historyTitle: "جستجو های اخیر",
                  text: selectedDestinationText,
                  onSelect: onSelectDestination,
                  onSelectHistory: onSelectDestinationHistory,
                  onChangeQuery: onChangeDestinationQuery,
                  query: destinationQuery,
                  loading: destinationLoading,
                  NoResult: DestinationNoResult
                }
              ]}
            />
          </div>
          <div className={styles["datepicker-wrapper"]}>
            <Datepicker
              error={dateError.length > 0}
              fromTitle={t("homepage.hotel.main_search.check_in")}
              fullWidth
              helperText={dateError}
              mode="start-end"
              onChange={onChangeDate}
              toTitle={t("homepage.hotel.main_search.check_out")}
              value={date}
            />
          </div>
        </div>
        <div className={styles["button-wrapper"]}>
          <Button
            aria-label="search"
            fullWidth
            id="hotel_page"
            onClick={onSubmitClick}
            size={isMobile ? "large" : "x-large"}
          >
            {t("action.search")}
          </Button>
        </div>
      </div>
    </div>
  );
};

export { SearchHotel };
