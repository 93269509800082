import { useAddRemoveQuery } from "@website/hooks";
import {
  Hotel,
  HotelInternational,
  VentureBus,
  VentureFlight,
  VentureFlightInt,
  VentureTrain
} from "@website/icons";
import { useTranslation } from "@website/locale";
import { useRouter } from "next/router";
import { useCallback, useMemo } from "react";

const products: Record<string, string> = {
  "/": "DH",
  "/train": "Train",
  "/interflights": "IF",
  "/inter-flights": "IF",
  "/flights": "DF",
  "/bus": "Bus",
  "/bus-international": "IB"
};

export const usePurchaseMenu = (snappTripToken?: string) => {
  const { t } = useTranslation();
  const router = useRouter();
  const key = "PURCHASE";
  const { [key]: isKey } = router.query;
  const { addQuery, removeQuery } = useAddRemoveQuery(key);

  const onPurchaseMenuOpen = useCallback(() => {
    window?.webengage?.track("mytrips-clicked", {
      source: process.env.NEXT_PUBLIC_APP_NAME
    });
    addQuery("open");
  }, [addQuery]);

  const onPurchaseMenuClose = useCallback(() => {
    removeQuery();
  }, [removeQuery]);

  const purchaseList = useMemo(() => {
    const onClickHandler = (selectedService: string, event: string) => {
      window?.webengage?.track(event, {
        source: process.env.NEXT_PUBLIC_APP_NAME
      });
      const query = {
        ...router.query,
        my_trips_tab: "0",
        selected_service: selectedService,
        source: String(process.env.NEXT_PUBLIC_APP_NAME),
        product: products[router?.pathname]
      };
      const queryString = new URLSearchParams(query).toString();
      window.location.href = `https://pwa.snapptrip.com/profile/my-trips?${
        queryString ?? ""
      }`;
    };

    return [
      {
        type: "button",
        title: t("services.domestic_hotels"),
        icon: <Hotel />,
        onClick: () => onClickHandler("HOTEL", "DH-mytrips-clicked")
      },
      {
        type: "button",
        title: t("services.inter_hotels"),
        icon: <HotelInternational />,
        onClick: () => onClickHandler("INTER_HOTEL", "IH-mytrips-clicked")
      },
      {
        type: "button",
        onClick: () => onClickHandler("FLIGHT", "DF-mytrips-clicked"),
        title: t("services.domestic_flights"),
        icon: <VentureFlight />
      },
      {
        type: "button",
        title: t("services.inter_flights"),
        icon: <VentureFlightInt />,
        onClick: () => onClickHandler("INTER_FLIGHT", "IF-mytrips-clicked")
      },
      {
        type: "button",
        onClick: () => onClickHandler("BUS", "Bus-mytrips-clicked"),
        title: t("services.bus"),
        icon: <VentureBus />
      },
      {
        type: "button",
        title: t("services.train"),
        icon: <VentureTrain />,
        onClick: () => onClickHandler("TRAIN", "Train-mytrips-clicked")
      }
    ];
  }, [t, router]);

  return {
    purchaseList,
    onPurchaseMenuOpen,
    onPurchaseMenuClose,
    purchaseMenuIsOpen: Boolean(isKey)
  };
};
