export const flight_domestic = {
  messages: {
    return_to_homepage: "بازگشت به صفحه اصلی"
  },
  search: {
    class_type: "کلاس پروازی"
  },
  result_page: {
    normal_solutions: "{number} پرواز  {origin} به {destination}",
    normal_solutions_loading: "در جستجوی پروازهای {origin} به {destination}...",
    others: "سایر",
    best_solutions: {
      header: "بهترین پروازها",
      detail: "ترتیب بر اساس قیمت و راحتی و امکانات"
    },
    telesales: {
      description:
        "بعد از ثبت درخواست، کارشناسان ما در اسرع وقت با شما تماس خواهند گرفت و شما را برای رزرو پرواز داخلی راهنمایی خواهند کرد."
    }
  }
};
