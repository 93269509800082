import { FC, memo, useCallback } from "react";
import { B2BInquiry, B2BOtp } from "./components";
import { LoginPropertiesType, LoginStepType } from "./login.types";
import { useB2BLogin } from "./use-b2b-login";

const LoginB2B: FC<LoginPropertiesType> = (properties) => {
  const { onEndAuth } = properties;
  const { step, setStep, userData, setUserData } = useB2BLogin();

  const setStepHandler = useCallback(
    (step: LoginStepType) => {
      setStep(step);
    },
    [setStep]
  );

  return (
    <div className="w-full">
      {step === "phone-number" && (
        <B2BInquiry
          setStep={setStepHandler}
          setUserData={setUserData}
          userData={userData}
        />
      )}
      {step === "otp" && (
        <B2BOtp
          onEndAuth={onEndAuth}
          setStep={setStepHandler}
          setUserData={setUserData}
          userData={userData}
        />
      )}
    </div>
  );
};

const LoginMemoized = memo(LoginB2B);
export { LoginMemoized as LoginB2B };
