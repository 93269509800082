import { deepCompare } from "@website/utils";
import { FC, memo } from "react";
import { Loading } from "../loading";
import { Skeleton } from "../skeleton";
import styles from "./message-wrapper.module.scss";
import { MessageWrapperPropertiesType } from "./message-wrapper.type";

const MessageWrapper: FC<MessageWrapperPropertiesType> = (properties) => {
  const {
    className,
    img,
    imgAlt,
    title,
    action,
    description,
    isLoading = false,
    isImageLoading = false,
    width,
    height,
    datatestid,
    imageStyle
  } = properties;
  if (!isLoading && action === undefined) {
    throw new Error(
      "Message wrapper:when isLoading is false action should be provided"
    );
  }
  return (
    <div
      className={`${styles["wrapper"]} ${className || ""}`}
      data-testid={datatestid}
    >
      {isImageLoading ? (
        <div className="mb-6 flex w-full justify-center">
          <Skeleton height={Number(height)} width={Number(width)} />
        </div>
      ) : (
        <img
          alt={imgAlt ?? "تصویر پیام"}
          className={`${styles.img} ${imageStyle || ""}`}
          height={height}
          src={img}
          width={width}
        />
      )}
      <h3 className={styles["header"]}>{title}</h3>
      <p className={styles["description"]}>{description}</p>
      {isLoading ? <Loading /> : action}
    </div>
  );
};

const MessageWrapperMemoized = memo(MessageWrapper, deepCompare);
export { MessageWrapperMemoized as MessageWrapper };
