import { MaterialIcon } from "@website/icons";
import { useTranslation } from "@website/locale";
import { CountDownTimer } from "@website/ui";
import { Button, DigitalTextField, Loading } from "@website/ui-old";
import { numberConvert } from "@website/utils";
import { FC, useCallback } from "react";
import { Header } from "..";
import { useOtpCard } from "./otp-card.hook";
import styles from "./otp-card.module.scss";
import { OtpCardPropertiesType } from "./otp-card.types";

export const OtpCard: FC<OtpCardPropertiesType> = (properties) => {
  const { onClose, userData } = properties;
  const {
    otp,
    onChangeOtp,
    onSubmitOtp,
    isLoading,
    error,
    showSendAgain,
    onClickSendAgain,
    isLoadingSendAgain,
    onTimerEnd,
    onBackClick,
    onSubmitOtpForgotPassword
  } = useOtpCard(properties);
  const { t } = useTranslation();

  const remainedTime = useCallback(() => {
    const now = Date.now();
    try {
      if (userData?.otpValidUntil) {
        return Math.floor(userData?.otpValidUntil - now / 1000) || 120;
      }
      return 120;
    } catch {
      return 120;
    }
  }, [userData?.otpValidUntil]);

  return (
    <div className={styles["container"]}>
      <Header
        onBack={onBackClick}
        onClose={onClose}
        title={t("login.entering_otp")}
      />
      <div className={styles["title"]}>
        {t(
          "login.send_otp_to_number",
          {
            number: numberConvert(userData?.phoneNumber, {
              locale: "fa"
            })
          } || ""
        )}
      </div>
      <div className={styles["sub-title"]}>
        {t("login.number_is_wrong")}
        <Button
          color="secondary"
          onClick={onBackClick}
          size="small"
          variant="text"
        >
          {t("login.edit")}
        </Button>
      </div>
      <form
        className="w-full"
        onSubmit={
          isLoading
            ? undefined
            : userData?.forgotPassword
              ? onSubmitOtpForgotPassword
              : onSubmitOtp
        }
      >
        <div className={styles["input"]}>
          <DigitalTextField
            error={Boolean(error)}
            helperText={error}
            num={userData?.otpLength || 5}
            onChange={onChangeOtp}
            value={otp}
          />
        </div>
        <div className={styles["info"]}>
          {showSendAgain ? (
            <>
              {t("login.dont_receive_verification_code")}
              <Button
                color="secondary"
                onClick={isLoadingSendAgain ? undefined : onClickSendAgain}
                size="small"
                type="button"
                variant="text"
              >
                {isLoadingSendAgain ? (
                  <Loading color="secondary" size="sm" />
                ) : (
                  t("login.send_again")
                )}
              </Button>
            </>
          ) : (
            <>
              {t("login.send_again_verification_code")}&nbsp;
              <CountDownTimer
                onTimerEnd={onTimerEnd}
                seconds={remainedTime()}
              />
            </>
          )}
        </div>
        <div className={styles["footer"]}>
          <Button
            color="secondary"
            fullWidth
            size="large"
            type="submit"
            variant="contained"
          >
            {isLoading ? (
              <Loading color="white" size="lg" />
            ) : userData?.userExists ? (
              t("login.accept_and_enter_to_snapptrip")
            ) : (
              t("login.accept_continue")
            )}
          </Button>
          <Button
            StartIcon={<MaterialIcon name="support_agent" />}
            color="secondary"
            fullWidth
            size="large"
            type="button"
            variant="text"
          >
            {t("login.call_support")}
          </Button>
        </div>
      </form>
    </div>
  );
};
