import { useLocalStorage } from "@website/hooks";
import { History } from "@website/icons";
import { useCallback, useEffect, useMemo, useState } from "react";
import {
  FLIGHT_ROUTE_HISTORY_LOCAL_STORAGE_KEY,
  FLIGHT_SEARCH_HISTORY_LOCAL_STORAGE_KEY
} from "../search-flights.constant";

export const useHistory = (
  onChangeOrigin: (item: any) => void,
  onChangeDestination: (item: any) => void
) => {
  const [history, setHistory] = useState<
    Array<{ origin: any; destination: any }>
  >([]);
  const { addToLocalStorage, getFromLocalStorage } = useLocalStorage();

  useEffect(() => {
    const historyString = getFromLocalStorage(
      FLIGHT_ROUTE_HISTORY_LOCAL_STORAGE_KEY
    );
    const history = historyString ? JSON.parse(historyString) : [];
    setHistory(history);
  }, [getFromLocalStorage]);

  const addToRouteHistory = useCallback(
    (origin, destination) => {
      const historyString = getFromLocalStorage(
        FLIGHT_ROUTE_HISTORY_LOCAL_STORAGE_KEY
      );
      const history = historyString ? JSON.parse(historyString) : [];

      let newHistory = history.filter(
        (item) =>
          item.origin.iataCode !== origin.iataCode &&
          item.destination.iataCode !== destination.iataCode
      );

      const newHistoryItem = {
        origin,
        destination
      };
      newHistory = [newHistoryItem, ...newHistory].slice(0, 2);

      addToLocalStorage(
        FLIGHT_ROUTE_HISTORY_LOCAL_STORAGE_KEY,
        JSON.stringify(newHistory)
      );
      setHistory(newHistory);
    },
    [addToLocalStorage, getFromLocalStorage]
  );

  const addToSearchHistory = useCallback(
    (
      origin: any,
      destination: any,
      startDateTime: any,
      endDateTime: any,
      calendarLocale: string,
      saleType: any,
      passengerNumber: any
    ) => {
      const historyString = getFromLocalStorage(
        FLIGHT_SEARCH_HISTORY_LOCAL_STORAGE_KEY
      );
      const history = historyString ? JSON.parse(historyString) : [];

      let newHistory = history.filter(
        (item) =>
          !(
            item.origin.iataCode === origin.iataCode &&
            item.destination.iataCode === destination.iataCode &&
            item.startDateTime === startDateTime &&
            (item.endDateTime && endDateTime
              ? item.endDateTime === endDateTime
              : true)
          )
      );

      const newHistoryItem = {
        origin,
        destination,
        startDateTime,
        endDateTime,
        calendarLocale,
        saleType,
        passengerNumber
      };
      newHistory = [newHistoryItem, ...newHistory].slice(0, 4);

      addToLocalStorage(
        FLIGHT_SEARCH_HISTORY_LOCAL_STORAGE_KEY,
        JSON.stringify(newHistory)
      );
    },
    [addToLocalStorage, getFromLocalStorage]
  );

  const addToHistory = useCallback(
    (
      origin: any,
      destination: any,
      startDateTime: any,
      endDateTime: any,
      calendarLocale: string,
      saleType: any,
      passengerNumber: any
    ) => {
      addToRouteHistory(origin, destination);
      addToSearchHistory(
        origin,
        destination,
        startDateTime,
        endDateTime,
        calendarLocale,
        saleType,
        passengerNumber
      );
    },
    [addToRouteHistory, addToSearchHistory]
  );

  const onSelectRouteHistory = useCallback(
    (id: string) => {
      if (history) {
        let historyItem = null;
        for (const element of history) {
          const checkKey = `${element.origin.iataCode}-${element.destination.iataCode}`;
          if (`${id}` === checkKey) {
            historyItem = element;
            break;
          }
        }

        if (historyItem) {
          onChangeOrigin(historyItem.origin);
          onChangeDestination(historyItem.destination);
        }
      }
    },
    [history, onChangeDestination, onChangeOrigin]
  );

  const routeHistoryList = useMemo(
    () =>
      history.map((item) => ({
        id: `${item?.origin?.iataCode}-${item?.destination?.iataCode}`,
        title: `${item?.origin?.cityFaName} - ${item?.destination?.cityFaName}`,
        StartIcon: <History />
      })),
    [history]
  );

  return {
    routeHistoryList,
    onSelectRouteHistory,
    addToHistory
  };
};
