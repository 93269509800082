import { AxiosNoAuth } from "../../../../utils";
import {
  AirportsItemType,
  AirportsParametersType,
  AirportsResponseType
} from "./airports.types";

export const AirportsUrl = `${process.env.NEXT_PUBLIC_BASE_URL_IFT}/api/statics/v2/airports-cities`;

export const AirportsService = (
  parameters: AirportsParametersType
): Promise<Array<AirportsItemType> | undefined> =>
  AxiosNoAuth.get(AirportsUrl, {
    params: { ...parameters, countryCode: "IR" }
  }).then(({ data }: { data: AirportsResponseType }) => data?.cities);
