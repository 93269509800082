import { useIsVisible, useViewPort } from "@website/hooks";
import { Loading } from "@website/ui";
import { deepCompare } from "@website/utils";
import { isEmpty } from "lodash";
import { useRouter } from "next/router";
import { memo } from "react";
import { ReserveComment } from "../reserve-comment";
import { ReserveRate } from "../reserve-rate";
import { ReserveCommentsFilter } from "./reserve-comment-filter";
import { ReserveCommentsFilterEmptyResult } from "./reserve-comment-filter/reserve-comment-filter-empty-result";
import { ReserveCommentPopupScrollTypes } from "./reserve-comment-popup-scroll.types";
import { ReserveCommentPopupSubmit } from "./reserve-comment-popup-submit";

const Component = ({
  rate,
  comments,
  onLoadMore,
  isLoading,
  setShowShadow,
  className,
  onLoadFilter,
  filters,
  isInsideDetail
}: ReserveCommentPopupScrollTypes) => {
  const router = useRouter();
  const { isMobile } = useViewPort();
  const { reference } = useIsVisible({
    onChange: (inView) => inView && onLoadMore?.()
  });

  const { reference: shadowReference } = useIsVisible({
    onChange: (inView) => (setShowShadow ? setShowShadow(!inView) : undefined)
  });

  return (
    <div
      className={`h-[calc(100%-64px)] overflow-y-auto ${className}`}
      id="container"
    >
      <div className="flex flex-col items-center gap-4 px-6 py-4 md:items-start md:gap-[40px] md:pb-[40px] md:pt-6 xl:flex-row 2xl:mx-auto 2xl:max-w-[1200px]">
        {rate && (
          <div className="mx-auto flex w-full flex-auto flex-col gap-6 xl:sticky xl:min-w-[312px] xl:max-w-[312px] xl:pt-[40px]">
            <ReserveRate
              {...rate}
              hasBackground={false}
              showTitle={false}
              size="medium"
            />
            {/*<div className={"bg-outline-thin hidden h-[1px] w-full md:block"} />*/}
            <ReserveCommentPopupSubmit />
          </div>
        )}
        <div className="flex w-full flex-col gap-6 md:self-start">
          <div ref={shadowReference} />
          {!isEmpty(filters) && (
            <ReserveCommentsFilter
              filters={filters}
              isInsideDetail={isInsideDetail}
              onLoadFilter={onLoadFilter}
            />
          )}
          {!isEmpty(filters) &&
          !isLoading &&
          !comments?.length &&
          Boolean(
            Object.keys(router?.query)?.filter(
              (item) => item?.startsWith("filter_")
            ).length
          ) ? (
            <div className="pt-[60px]">
              <ReserveCommentsFilterEmptyResult onLoadFilter={onLoadFilter} />
            </div>
          ) : (
            comments?.map((comment) => (
              <ReserveComment key={comment?.id} {...comment} />
            ))
          )}
          {isLoading && (
            <div className="mx-auto">
              <Loading color="primary" size={isMobile ? "sm" : "lg"} />
            </div>
          )}
          <div ref={reference} />
        </div>
      </div>
    </div>
  );
};
export const ReserveCommentPopupScroll = memo(Component, deepCompare);
