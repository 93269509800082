import { memo, SVGProps } from "react";

const SvgPayBadge = (properties: SVGProps<SVGSVGElement>) => (
  <svg
    fill="none"
    height="15"
    viewBox="0 0 15 15"
    width="15"
    xmlns="http://www.w3.org/2000/svg"
    {...properties}
  >
    <path
      d="M0.333008 14.666L9.66634 11.3327L3.66634 5.33266L0.333008 14.666Z"
      fill="white"
    />
    <path
      d="M8.68634 8.35266L12.413 4.62599C12.7397 4.29932 13.2663 4.29932 13.593 4.62599L13.9863 5.01932L14.693 4.31266L14.2997 3.91932C13.5863 3.20599 12.4197 3.20599 11.7063 3.91932L7.97967 7.64599L8.68634 8.35266Z"
      fill="white"
    />
    <path
      d="M5.70634 4.58599L5.31301 4.97932L6.01967 5.68599L6.41301 5.29266C7.12634 4.57932 7.12634 3.41266 6.41301 2.69932L6.01967 2.30599L5.31301 3.01932L5.70634 3.41266C6.02634 3.73266 6.02634 4.26599 5.70634 4.58599Z"
      fill="white"
    />
    <path
      d="M10.373 7.91932L9.31301 8.97932L10.0197 9.68599L11.0797 8.62599C11.4063 8.29932 11.933 8.29932 12.2597 8.62599L13.333 9.69932L14.0397 8.99266L12.9663 7.91932C12.2463 7.20599 11.0863 7.20599 10.373 7.91932Z"
      fill="white"
    />
    <path
      d="M9.03967 3.91932L6.64634 6.31266L7.35301 7.01932L9.74634 4.62599C10.4597 3.91266 10.4597 2.74599 9.74634 2.03266L8.68634 0.972656L7.97967 1.67932L9.03967 2.73932C9.35968 3.06599 9.35968 3.59932 9.03967 3.91932Z"
      fill="white"
    />
  </svg>
);

const Memo = memo(SvgPayBadge);
export default Memo;
