import { useBack } from "@website/hooks";
import { useRouter } from "next/router";
import { useEffect, useState } from "react";
import { StepperProperties } from "./stepper.types";

export const useStepper = (properties: StepperProperties) => {
  const { steps, activeStep, backUrlLink, previousButton } = properties;
  const { goBack } = useBack();
  const router = useRouter();

  const onGoBackLink = () => {
    router.replace(backUrlLink);
  };
  const showBackButton = previousButton || backUrlLink;

  const [active, setActive] = useState<number>(0);
  useEffect(() => {
    const currentStep =
      steps[Number(activeStep)] === undefined
        ? activeStep <= 0
          ? 0
          : steps.length
        : activeStep;

    setActive(currentStep || 0);
  }, [steps, activeStep]);

  return {
    onGoBack: backUrlLink ? onGoBackLink : goBack,
    stepperProperties: {
      steps,
      active
    },
    showBackButton
  };
};
