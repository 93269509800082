globalThis["__sentryRewritesTunnelPath__"] = undefined;
globalThis["SENTRY_RELEASE"] = {"id":"2cd2bdb1a97f914d65023581937f0e4bf5294a1e"};
globalThis["__sentryBasePath"] = undefined;
globalThis["__rewriteFramesAssetPrefixPath__"] = "";
import { init } from "@sentry/nextjs";

init({
  environment: process.env.NEXT_PUBLIC_BUILD_ENV,
  release: process.env.NEXT_PUBLIC_IMAGE_VERSION,
  dsn: process.env.NEXT_PUBLIC_SENTRY_DSN,
  tracesSampleRate: 0.2
});
