export const ticket_ui = {
  global: {
    currency: "تومان",
    departure: "رفت",
    return: "برگشت",
    roundtrip: "رفت و برگشت",
    charter: "چارتری",
    system: "سیستمی",
    hour: "ساعت"
  },

  filter_airlines: {
    choose_airline: "انتخاب ایرلاین",
    search: "جستجوی در ایرلاین",
    more: "نمایش بیشتر",
    less: "نمایش کمتر"
  },
  filter_flight_type: {
    flight_type: "نوع پرواز"
  },
  filter_offers: {
    title: "پیشنهادات ویژه"
  },
  filter_price: {
    title: "قیمت یک بزرگسال"
  },
  filter_time: {
    title: "زمان حرکت",
    from: "حرکت از",
    from_roundtrip: "حرکت از مبدا"
  },
  filter_flight_duration: {
    title: "مدت زمان سفر",
    title_roundtrip: " مدت زمان سفر در هر مسیر"
  },
  filter_stop_duration: {
    title: "مدت زمان توقف",
    title_roundtrip: "مجموع مدت زمان توقف هر مسیر",
    no_stop: "بدون توقف"
  },
  overtime_modal: {
    title: "بروز‌رسانی جستجو",
    description: `قیمت و ظرفیت پروازها دائما در حال تغییر هستند. برای اطمینان از آخرین
          قیمت و وضعیت پروازها لطفا جستجو را بروز‌رسانی نمایید.`,
    button: "بروز‌رسانی جستجو"
  },
  sort_modal: {
    title: "مرتب سازی"
  },
  flight_card: {
    remaining_seats: "{seats} صندلی باقی مانده",
    price: "قیمت برای هر بزرگسال",
    next_step: "مشاهده پرواز",
    non_refundable: "غیر قابل استرداد",
    one_way_flight: "یک ‌طرفه"
  },
  flightـbookingـinfo: {
    detail: "جزئیات پرواز"
  },
  flight_detail: {
    domestic_title: "جزئیات پرواز داخلی",
    international_title: "جزئیات رزرو پرواز خارجی",
    purchase_detail_time: "زمان سفارش",
    purchase_success: "سفارش موفق",
    one_way_flight: "پرواز یکطرفه",
    two_way_flight: "پرواز رفت و برگشت",
    price_unit: "تومان",
    final_price: "مبلغ کل",
    ticket_download: "دانلود بلیت",
    canceling_status: "در حال کنسلی",
    cancelled: "کنسل شده",
    cancelling: "در حال کنسلی",
    submitted: "کنسل توسط کاربر",
    "cancellation-rejected": "لغو نامعتبر",
    refunding: "باز پرداخت",
    refunded: "بازپرداخت شده",
    cancelation_null: "سفارش موفق",
    SUCCESSFUL: "سفارش موفق",
    FAILED: "صادر نشده",
    FAILED_WITH_REFERENCE: "نامشخص",
    CANCELLED: "کنسل شده",
    notassigned: "نامشخص",
    "issue-succeeded": "سفارش موفق",
    "issue-failed": "سفارش ناموفق",
    "issue-failed-with-reference": "صدور نامشخص",
    NOT_CANCELLED: "سفارش موفق",
    IN_PROGRESS: "در حال پردازش",
    PENDING: "در انتظار پاسخ",
    PAYING: "در حال پرداخت",
    NON_CANCELLABLE: "غیر قابل کنسل",
    phone_number: "شماره موبایل",
    tracking_code: "کد پیگیری",
    departure_date: "تاریخ رفت",
    return_date: "تاریخ برگشت",
    passenger_count: "تعداد مسافر",
    ticket_no: "شماره بلیط",
    national_code: "کد‌ملی",
    passport_number: "شماره گذرنامه",
    failed_get_error: "خطایی رخ داده است",
    ticket_default_download: "دانلود بلیت پیش‌فرض",
    ticket_custom_download: "دانلود بلیت سفارشی سازی ‌شده",
    ticket_custom: {
      modal_title: "سفارشی‌ سازی بلیط",
      determine_details: "جزئیات قیمت بلیت را تعیین کنید",
      before_off: "دانلود براساس قیمت قبل از تخفیف",
      departure_flight: "پرواز رفت",
      return_flight: "پرواز برگشت",
      mixed_price_title_flight: "پرواز",
      adult_price: "قیمت بزرگسال",
      child_price: "قیمت کودک",
      download_customized_ticket: "دانلود بلیت سفارشی‌شده",
      error: "خطایی رخ داده است، دوباره تلاش کنید"
    }
  },
  sale_type: {
    one_way: "یک طرفه",
    round_trip: "رفت و برگشت"
  },
  class_type: {
    economy: "اکونومی",
    business: "بیزینس",
    first_class: "فرست کلاس",
    all: "همه پروازها"
  },
  no_result: {
    title: "نتیجه ای یافت نشد.",
    description_first_part: "نتیجه ای برای ",
    description_second_part: " پیدا نکردیم.",
    search_another_word: "با کلمه ی دیگری جستجو کنید.",
    search_count: "بیشتر از {count} مورد را گشتیم ",
    but: " اما "
  },
  trip_type: "نوع مسیر",
  messages: {
    load_error_title: "خطایی رخ داده است!",
    load_error_main_description:
      "متاسفانه در بارگزاری اطلاعات خطایی رخ داده است.",
    load_error_sub_description: "لطفا مجدد تلاش کنید.",
    retry: "تلاش مجدد",
    loading_title: "در حال پیدا کردن بهترین پروازها برای شما هستیم.",
    loading_description: "جستجوی پروازهای ",
    to: "به",
    error_title: "خطایی رخ داده است!",
    error_description: "متاسفانه اطلاعاتی یافت نشد.",
    no_result_description: "لطفا روزهای دیگر را بررسی کنید.",
    return_to_homepage: "بازگشت به صفحه اصلی",
    not_found_title: "نتیجه‌ای یافت نشد.",
    not_found_description:
      "متاسفانه در حال حاضر پروازی متناسب با فیلتر های انتخابی شما وجود ندارد . ",
    delete_filters: "حذف فیلترها"
  },
  result_page: {
    loading: {
      description: "در حال پیدا کردن بهترین پرواز‌‌ها برای شما هستیم.",
      title: "جست‌و‌جو‌‌ی‌ پروازهای {origin} به {destination}..."
    },
    show_more: "مشاهده {count} نتیجه دیگر"
  },
  solution_detail: {
    close: "بستن",
    select_ticket_and_continue: "انتخاب بلیت و ادامه"
  },
  search_panel: {
    your_recent_searches: "آخرین جستجوهای شما"
  },
  passengers_type: {
    ADULT: "بزرگسال",
    CHILD: "کودک",
    INFANT: "نوزاد"
  },
  flight_detail_price: {
    close: "بستن",
    price_details: "جزییات قیمت"
  }
};
