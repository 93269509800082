import { useTranslation } from "@website/locale";
import { Button, Loading } from "@website/ui-old";
import { FC } from "react";
import { Header } from "..";
import { PasswordField } from "../../..";
import { useNewPasswordCard } from "./new-password-card.hook";
import styles from "./new-password-card.module.scss";
import { NewPasswordCardPropertiesType } from "./new-password-card.types";

export const NewPasswordCard: FC<NewPasswordCardPropertiesType> = (
  properties
) => {
  const {
    error,
    password,
    onChangePassword,
    onSubmitPassword,
    isLoading,
    onCloseClick,
    onBackClick
  } = useNewPasswordCard(properties);
  const { t } = useTranslation();

  return (
    <div className={styles["container"]}>
      <Header
        onBack={onBackClick}
        onClose={onCloseClick}
        title={t("login.new_password")}
      />
      <form
        className="w-full"
        onSubmit={isLoading ? undefined : onSubmitPassword}
      >
        <div className={styles["title"]}>
          {t("login.please_enter_new_password")}
        </div>
        <div className={styles["input"]}>
          <PasswordField
            error={Boolean(error)}
            fullWidth
            helperText={error}
            initialValue={password}
            onChange={onChangePassword}
            placeholder={t("login.new_password")}
          />
        </div>
        <div className={styles["footer"]}>
          <Button
            color="secondary"
            fullWidth
            size="large"
            type="submit"
            variant="contained"
          >
            {isLoading ? (
              <Loading color="white" size="lg" />
            ) : (
              t("login.accept_continue")
            )}
          </Button>
        </div>
      </form>
    </div>
  );
};
