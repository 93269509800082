export type EnvironmentChannelType =
  | "ap"
  | "jek"
  | "web"
  | "web-ir"
  | "ads"
  | "telesale"
  | "snapp-ir"
  | "pwa"
  | "website"
  | "b2b";

export enum Nationality {
  Iranian = "iranian",
  Foreign = "foreign"
}

export type PassengerStoreType = {
  firstName?: string;
  lastName?: string;
  gender?: string;
  nationality?: string;
  nationalId?: string;
  passportNumber?: string;
  passportIssuePlace?: string;
  passportExpirationDate?: string;
  id?: string;
  birthdate?: string;
};
