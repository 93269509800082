import { saveLogin } from "@website/hooks";
import { authStore } from "@website/store";
import { ModalOldAnimateType, ModalOldMobileHeightType } from "@website/ui-old";
import { isSSR } from "@website/utils";
import { omit } from "lodash";
import { NextRouter, useRouter } from "next/router";
import { useCallback, useEffect, useState } from "react";

const validQueryStrings = new Set([
  "phone-number",
  "otp",
  "password",
  "forgot-password",
  "password-change-successful",
  "add-password"
]);

export const openLoginModal = (router: NextRouter) =>
  new Promise((resolve, reject) => {
    router
      .replace(
        {
          pathname: router?.pathname,
          query: {
            ...router.query,
            modal: "login",
            login_step: "phone-number"
          }
        },
        undefined,
        {
          scroll: false,
          shallow: true
        }
      )
      .then(() => {
        document.addEventListener("onLoginModalEnd", resolve);
        document.addEventListener("onLoginModalClose", reject);
      });
  });

export const useLoginModal = () => {
  const [isLogin, setIsLogin] = useState<boolean>(false);
  const queryString = "login_step";
  const router = useRouter();
  const queryValue = router?.query?.[queryString];

  useEffect(() => {
    const accessToken = authStore.accessToken.get();
    if (accessToken) {
      setIsLogin(true);
    }
  }, []);

  const onLoginModalOpen = useCallback(() => {
    router?.push(
      {
        pathname: router?.pathname,
        query: { ...router?.query, [queryString]: "phone-number" }
      },
      undefined,
      { shallow: true, scroll: false }
    );
  }, [router]);

  const onLoginModalClose = useCallback(() => {
    document.dispatchEvent(
      new CustomEvent("onLoginModalClose", {
        detail: {}
      })
    );
    router?.push(
      {
        pathname: router?.pathname,
        query: omit(router?.query, [queryString, "modal", "login_step"])
      },
      undefined,
      { shallow: true, scroll: false }
    );
  }, [router]);

  const onLoginModalEnd = useCallback(
    (accessToken?: string, refreshToken?: string) => {
      document.dispatchEvent(
        new CustomEvent("onLoginModalEnd", {
          detail: { accessToken, refreshToken }
        })
      );
      if (accessToken) {
        saveLogin({ accessToken, refreshToken });
        setIsLogin(!isSSR());
      }
      onLoginModalClose();
    },
    [onLoginModalClose]
  );

  return {
    loginModalIsOpen: Boolean(
      typeof queryValue === "string" && validQueryStrings.has(queryValue)
    ),
    onLoginModalOpen,
    onLoginModalClose,
    onLoginModalEnd,
    loginModalProps: {
      open: Boolean(
        typeof queryValue === "string" && validQueryStrings.has(queryValue)
      ),
      onClose: router.query.modal ? undefined : onLoginModalClose,
      mobileHeight: "auto" as ModalOldMobileHeightType,
      animate: "bottom" as ModalOldAnimateType
    },
    isLogin
  };
};
