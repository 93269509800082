import { useTranslation } from "@website/locale";
import { useCallback, useState } from "react";
import { ResetPasswordVerifyOtpService } from "../../services/reset-password-verify-otp";
import { NewPasswordCardPropertiesType } from "./new-password-card.types";

export const useNewPasswordCard = (
  properties: NewPasswordCardPropertiesType
) => {
  const { setStep, onEndAuth, userData } = properties;
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [error, setError] = useState<string>("");
  const [password, setPassword] = useState<string>("");
  const { t } = useTranslation();

  const onChangePassword = useCallback((value: string) => {
    setPassword(value);
  }, []);

  const onSubmitPassword = useCallback(
    async (_event: React.FormEvent<HTMLFormElement>) => {
      _event.preventDefault();
      if (password) {
        setIsLoading(true);
        setError("");
        const { otp, otpId } = userData;

        if (otp && otpId) {
          ResetPasswordVerifyOtpService({
            code: otp,
            newPassword: password,
            otpId: otpId
          })
            .then(() => {
              setStep("password-change-successful");
            })
            .catch((error) => {
              setError(
                error?.response?.data?.message ||
                  t("login.general_error_message")
              );
            })
            .finally(() => setIsLoading(false));
        } else {
          setStep("phone-number");
        }
      } else {
        setError(t("login.enter_your_password"));
      }
    },
    [password, setStep, t, userData]
  );

  const onCloseClick = useCallback(() => {
    onEndAuth?.();
  }, [onEndAuth]);

  const onBackClick = useCallback(() => {
    setStep("otp");
  }, [setStep]);

  return {
    isLoading,
    password,
    onBackClick,
    onCloseClick,
    onChangePassword,
    onSubmitPassword,
    error
  };
};
