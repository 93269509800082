import { useViewPort } from "@website/hooks";
import { useTranslation } from "@website/locale";
import {
  AutoComplete,
  Button,
  Datepicker,
  ToggleButton
} from "@website/ui-old";
import { FC, useCallback, useState } from "react";
import { useQuery } from "react-query";
import { FlightClass, PassengerCount, SaleType } from "../../..";
import { AxiosNoAuth } from "../../../../utils";
import {
  useDate,
  useDestination,
  useFlightClass,
  useHistory,
  useOrigin,
  usePassengerCount,
  useSaleType,
  useSubmit,
  useToggle,
  useValidation
} from "./hooks";
import styles from "./search-inter-flights.module.scss";
import { SearchInterFlightsPropertiesType } from "./search-inter-flights.types";

const SearchInterFlights: FC<SearchInterFlightsPropertiesType> = () => {
  const {
    selectedOriginItem,
    selectedOriginText,
    originQuery,
    onChangeOrigin,
    onChangeOriginQuery,
    onSelectOrigin,
    originLoading,
    originListNormalized,
    originLabel,
    OriginNoResult
  } = useOrigin();
  const {
    selectedDestinationItem,
    selectedDestinationText,
    destinationQuery,
    onChangeDestination,
    onChangeDestinationQuery,
    onSelectDestination,
    destinationLoading,
    destinationListNormalized,
    destinationLabel,
    DestinationNoResult
  } = useDestination();
  const { saleType, saleTypeList, onChangeSaleType, onChangeDatepickerMode } =
    useSaleType();
  const { flightClass, flightClassList, onChangeFlightClass } =
    useFlightClass();
  const { date, calendarLocale, onChangeDate, fromTitle, toTitle } = useDate();
  const {
    onSubmitPassengerCount,
    passengerCount,
    passengerCountList,
    passengerCountResult,
    passengerCountTitle,
    onClickBackDropHandler,
    onClosePassengerButton
  } = usePassengerCount();
  const {
    destinationRouteHistoryList,
    originRouteHistoryList,
    addToHistory,
    onSelectDestinationRouteHistory,
    onSelectOriginRouteHistory
  } = useHistory(onChangeOrigin, onChangeDestination);
  const { originError, destinationError, dateError, submitValidationCheck } =
    useValidation({
      originItem: selectedOriginItem,
      destinationItem: selectedDestinationItem,
      saleType,
      date
    });
  const [submitLoading, setSubmitLoading] = useState<boolean>(false);

  const { onSubmitClick, submitText } = useSubmit(
    selectedOriginItem,
    selectedDestinationItem,
    saleType,
    date,
    calendarLocale,
    passengerCount,
    flightClass,
    addToHistory,
    submitValidationCheck,
    setSubmitLoading
  );
  const { toggleList, toggleValue } = useToggle();
  const { isMobile } = useViewPort();
  const today = new Date();
  const year = today.getFullYear();
  const month = today.getMonth() + 1;
  const day = today.getDate();
  const formattedDate = `${year}-${month < 10 ? `0${month}` : month}-${
    day < 10 ? `0${day}` : day
  }`;
  const { t } = useTranslation();

  const onSwap = useCallback(() => {
    onChangeOrigin(selectedDestinationItem);
    onChangeDestination(selectedOriginItem);
  }, [
    onChangeOrigin,
    selectedDestinationItem,
    onChangeDestination,
    selectedOriginItem
  ]);

  const { data: pricesList } = useQuery({
    queryKey: [
      `${process.env.NEXT_PUBLIC_BASE_URL_IFT}/api/price-table/calendar`,
      formattedDate,
      selectedOriginItem?.iataCode,
      selectedDestinationItem?.iataCode,
      !selectedOriginItem?.airportIataCode,
      !selectedDestinationItem?.airportIataCode
    ],
    queryFn: () =>
      AxiosNoAuth.get(
        `${process.env.NEXT_PUBLIC_BASE_URL_IFT}/api/price-table/calendar`,
        {
          params: {
            date: formattedDate,
            origin: selectedOriginItem?.iataCode,
            destination: selectedDestinationItem?.iataCode,
            origin_is_city: !selectedOriginItem?.airportIataCode,
            destination_is_city: !selectedDestinationItem?.airportIataCode
          }
        }
      ).then(({ data }: any) => data),
    enabled:
      Boolean(selectedOriginItem?.iataCode) &&
      Boolean(selectedDestinationItem?.iataCode) &&
      !selectedOriginItem?.airportIataCode &&
      !selectedDestinationItem?.airportIataCode &&
      saleType === "one-way" &&
      flightClass === "ECONOMY"
  });

  return (
    <div className={styles["container"]}>
      <div className={styles["options"]}>
        <ToggleButton
          color="secondary"
          list={toggleList}
          size="small"
          value={toggleValue}
          variant="contained"
        />
        <div className={styles["filters"]}>
          <SaleType
            list={saleTypeList}
            name="flight-international-sale-type"
            onChange={onChangeSaleType}
            title={t("homepage.inter_flights.sale_type.title")}
            value={saleType}
          />
          <PassengerCount
            list={passengerCountList}
            onClickBackDropHandler={onClickBackDropHandler}
            onClosePassengerButton={onClosePassengerButton}
            onSubmit={onSubmitPassengerCount}
            result={passengerCountResult}
            title={passengerCountTitle}
          />
          <FlightClass
            list={flightClassList}
            name="flight-international-class-type"
            onChange={onChangeFlightClass}
            title={t("homepage.inter_flights.sale_type.title")}
            value={flightClass}
          />
        </div>
      </div>
      <div className={styles["main"]}>
        <div className={styles["inputs-wrapper"]}>
          <div className={styles["autocomplete-wrapper"]}>
            <AutoComplete
              error={originError || destinationError}
              fullWidth
              onSwap={onSwap}
              parts={[
                {
                  label: originLabel,
                  list: originListNormalized,
                  historyList:
                    originQuery.length <= 1 ? originRouteHistoryList : [],
                  historyTitle: "آخرین جستجوهای شما",
                  text: selectedOriginText,
                  onSelect: onSelectOrigin,
                  onSelectHistory: onSelectOriginRouteHistory,
                  onChangeQuery: onChangeOriginQuery,
                  query: originQuery,
                  loading: originLoading,
                  NoResult: OriginNoResult
                },
                {
                  label: destinationLabel,
                  list: destinationListNormalized,
                  historyList:
                    destinationQuery.length <= 1
                      ? destinationRouteHistoryList
                      : [],
                  historyTitle: "آخرین جستجوهای شما",
                  text: selectedDestinationText,
                  onSelect: onSelectDestination,
                  onSelectHistory: onSelectDestinationRouteHistory,
                  onChangeQuery: onChangeDestinationQuery,
                  query: destinationQuery,
                  loading: destinationLoading,
                  NoResult: DestinationNoResult
                }
              ]}
            />
          </div>
          <div className={styles["datepicker-wrapper"]}>
            <Datepicker
              changeableMode
              error={dateError.length > 0}
              fromTitle={fromTitle}
              fullWidth
              helperText={dateError}
              mode={saleType === "one-way" ? "single" : "same-start-end"}
              onChange={onChangeDate}
              prices={
                saleType === "one-way" && flightClass === "ECONOMY"
                  ? pricesList
                  : []
              }
              secondaryMode="same-start-end"
              setMode={onChangeDatepickerMode}
              toTitle={toTitle}
              value={date}
            />
          </div>
        </div>
        <div className={styles["button-wrapper"]}>
          <Button
            aria-label="search"
            fullWidth
            id="interflights_page"
            loading={submitLoading}
            onClick={onSubmitClick}
            size={isMobile ? "large" : "x-large"}
          >
            {submitText}
          </Button>
        </div>
      </div>
    </div>
  );
};

export { SearchInterFlights };
