export const SchemaLocalBusiness = ({
  name,
  imageUrl
}: {
  name?: string;
  imageUrl?: string;
}) => (
  <script
    dangerouslySetInnerHTML={{
      __html: JSON.stringify({
        "@context": "https://schema.org/",
        "@type": "LocalBusiness",
        name: name || "اسنپ تریپ",
        address: "Tehran Province, Tehran, Mehrdad, QCCC+977, Iran",
        image: imageUrl || "/favicon.ico",
        geo: {
          "@type": "GeoCoordinates",
          latitude: "35.7709181",
          longitude: "51.4180978"
        },
        openingHoursSpecification: {
          "@type": "OpeningHoursSpecification",
          validFrom: "",
          opens: "09:00",
          closes: "18:00",
          dayOfWeek: [
            "Monday",
            "Tuesday",
            "Wednesday",
            "Thursday",
            "Friday",
            "Saturday",
            "Sunday"
          ]
        },
        telephone: "02196669066",
        priceRange: "IRR"
      })
    }}
    type="application/ld+json"
  />
);
