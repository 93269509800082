import { useViewPort } from "@website/hooks";
import { useTranslation } from "@website/locale";
import { useRouter } from "next/router";
import { useMemo } from "react";

export const useToggle = () => {
  const { t } = useTranslation();
  const router = useRouter();
  const query = new URLSearchParams(router.query as any).toString();
  const { pathname } = router;
  const { isMobile } = useViewPort();
  const channel = process.env.NEXT_PUBLIC_CHANNEL_TYPE;
  const toggleList = useMemo(() => {
    if (channel === "jek" || isMobile) {
      return [
        {
          text: t("homepage.services.domestic_flights"),
          value: `/flights${query ? `?${query}` : ""}`,
          role: "next-link" as const,
          href: `/flights${query ? `?${query}` : ""}`,
          replace: channel === "jek"
        },
        {
          text: t("homepage.services.inter_flights"),
          value: `${channel === "jek" ? "/interflights" : "/inter-flights"}${
            query ? `?${query}` : ""
          }`,
          role: "next-link" as const,
          href: `${channel === "jek" ? "/interflights" : "/inter-flights"}${
            query ? `?${query}` : ""
          }`,
          replace: channel === "jek"
        },
        {
          text: t("homepage.services.visa"),
          value: `/home-visa${query ? `?${query}` : ""}`,
          role: "next-link" as const,
          href: `/home-visa${query ? `?${query}` : ""}`,
          replace: channel === "jek"
        }
      ];
    }
    return [
      {
        text: t("homepage.services.inter_flights"),
        value: `/inter-flights${query ? `?${query}` : ""}`,
        role: "next-link" as const,
        href: `/inter-flights${query ? `?${query}` : ""}`
      },
      {
        text: t("homepage.services.visa"),
        value: `/home-visa${query ? `?${query}` : ""}`,
        role: "next-link" as const,
        href: `/home-visa${query ? `?${query}` : ""}`
      }
    ];
  }, [channel, t, query, isMobile]);

  const toggleValue = `${pathname}${query ? `?${query}` : ""}`;

  return {
    toggleList,
    toggleValue
  };
};
