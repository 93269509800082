import { useViewPort } from "@website/hooks";
import { useTranslation } from "@website/locale";
import {
  AutoComplete,
  Button,
  Datepicker,
  ToggleButton
} from "@website/ui-old";
import { FC, useCallback } from "react";
import { PassengerCount, SaleType } from "../../..";
import { useFlightClass } from "../search-inter-flights/hooks";
import {
  useDate,
  useDestination,
  useHistory,
  useOrigin,
  usePassengerCount,
  useSaleType,
  useSubmit,
  useToggle,
  useValidation
} from "./hooks";
import styles from "./search-flights.module.scss";
import { SearchFlightsPropertiesType } from "./search-flights.types";

const SearchFlights: FC<SearchFlightsPropertiesType> = (properties) => {
  const {
    selectedOriginItem,
    selectedOriginText,
    originQuery,
    onChangeOrigin,
    onChangeOriginQuery,
    onSelectOrigin,
    originLoading,
    originListNormalized,
    originLabel,
    OriginNoResult
  } = useOrigin();
  const {
    selectedDestinationItem,
    selectedDestinationText,
    destinationQuery,
    onChangeDestination,
    onChangeDestinationQuery,
    onSelectDestination,
    destinationLoading,
    destinationListNormalized,
    destinationLabel,
    DestinationNoResult
  } = useDestination();
  const { saleType, saleTypeList, onChangeSaleType, onChangeDatepickerMode } =
    useSaleType();
  const {
    onSubmitPassengerCount,
    passengerCount,
    passengerCountList,
    passengerCountResult,
    passengerCountTitle,
    onClosePassengerButton,
    onClickBackDropHandler
  } = usePassengerCount();

  const { date, calendarLocale, onChangeDate, fromTitle, toTitle } = useDate();
  const { routeHistoryList, addToHistory, onSelectRouteHistory } = useHistory(
    onChangeOrigin,
    onChangeDestination
  );

  const { flightClass } = useFlightClass();

  const { originError, destinationError, dateError, submitValidationCheck } =
    useValidation({
      originItem: selectedOriginItem,
      destinationItem: selectedDestinationItem,
      saleType,
      date
    });
  const {
    onSubmitClick,
    // submitLoading,
    submitText
  } = useSubmit(
    selectedOriginItem,
    selectedDestinationItem,
    saleType,
    date,
    calendarLocale,
    passengerCount,
    flightClass,
    addToHistory,
    submitValidationCheck,
    properties
  );
  const { toggleList, toggleValue } = useToggle();
  const channel = process.env.NEXT_PUBLIC_CHANNEL_TYPE;
  const { isMobile } = useViewPort();
  const { t } = useTranslation();

  const onSwap = useCallback(() => {
    onChangeOrigin(selectedDestinationItem);
    onChangeDestination(selectedOriginItem);
  }, [
    onChangeOrigin,
    selectedDestinationItem,
    onChangeDestination,
    selectedOriginItem
  ]);

  return (
    <div className={styles["container"]}>
      <div className={styles["options"]}>
        {channel === "jek" || isMobile ? (
          <ToggleButton
            color="secondary"
            list={toggleList}
            size="small"
            value={toggleValue}
            variant="contained"
          />
        ) : null}
        <div className={styles["filters"]}>
          <SaleType
            list={saleTypeList}
            name="flight-domestic"
            onChange={onChangeSaleType}
            title={t("homepage.flights.sale_type.title")}
            value={saleType}
          />
          <PassengerCount
            list={passengerCountList}
            onClickBackDropHandler={onClickBackDropHandler}
            onClosePassengerButton={onClosePassengerButton}
            onSubmit={onSubmitPassengerCount}
            result={passengerCountResult}
            title={passengerCountTitle}
          />
        </div>
      </div>
      <div className={styles["main"]}>
        <div className={styles["inputs-wrapper"]}>
          <div className={styles["autocomplete-wrapper"]}>
            <AutoComplete
              error={originError || destinationError}
              fullWidth
              onSwap={onSwap}
              parts={[
                {
                  label: originLabel,
                  list: originListNormalized,
                  historyList: originQuery.length <= 1 ? routeHistoryList : [],
                  historyTitle: "آخرین جستجوهای شما",
                  text: selectedOriginText,
                  onSelect: onSelectOrigin,
                  onSelectHistory: onSelectRouteHistory,
                  onChangeQuery: onChangeOriginQuery,
                  query: originQuery,
                  loading: originLoading,
                  NoResult: OriginNoResult
                },
                {
                  label: destinationLabel,
                  list: destinationListNormalized,
                  historyList:
                    destinationQuery.length <= 1 ? routeHistoryList : [],
                  historyTitle: "آخرین جستجوهای شما",
                  text: selectedDestinationText,
                  onSelect: onSelectDestination,
                  onSelectHistory: onSelectRouteHistory,
                  onChangeQuery: onChangeDestinationQuery,
                  query: destinationQuery,
                  loading: destinationLoading,
                  NoResult: DestinationNoResult
                }
              ]}
            />
          </div>
          <div className={styles["datepicker-wrapper"]}>
            <Datepicker
              changeableMode
              error={dateError.length > 0}
              fromTitle={fromTitle}
              fullWidth
              helperText={dateError}
              mode={saleType === "one-way" ? "single" : "same-start-end"}
              onChange={onChangeDate}
              secondaryMode="same-start-end"
              setMode={onChangeDatepickerMode}
              toTitle={toTitle}
              value={date}
            />
          </div>
        </div>
        <div className={styles["button-wrapper"]}>
          <Button
            aria-label="search"
            fullWidth
            id="flights_page"
            // loading={submitLoading}
            onClick={onSubmitClick}
            size={isMobile ? "large" : "x-large"}
          >
            {submitText}
          </Button>
        </div>
      </div>
    </div>
  );
};

export { SearchFlights };
