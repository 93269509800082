import { useJwtToken } from "@website/hooks";
import { authStore, useSelector } from "@website/store";
import { useRouter } from "next/router";
import { useCallback, useEffect, useState } from "react";
import { useMutation } from "react-query";
import { growthbook } from "../../pages/_app.page";
import {
  ChangeTokenService,
  ChangeTokenUrl
} from "./../../services/base/homepage/jek/change-token";
import { pathsHasAuth, pathsHasSearch } from "./layout-constant";

export const useLayout = () => {
  const router = useRouter();
  const [snappTripToken, setSnappTripToken] = useState("");
  const channel = process.env.NEXT_PUBLIC_CHANNEL_TYPE;
  const accessToken = useSelector(authStore.accessToken);

  const snappToken =
    typeof window === "object"
      ? new URLSearchParams(window?.location?.search)?.get("token") || ""
      : "";

  const { mutateAsync } = useMutation(
    [
      ChangeTokenUrl,
      {
        token: snappToken,
        tokenType: "SNAPP_TOKEN"
      }
    ],
    () =>
      ChangeTokenService({
        token: snappToken,
        tokenType: "SNAPP_TOKEN"
      })
  );
  useEffect(() => {
    if (channel === "b2b" && !accessToken && router.asPath !== "/b2b-login") {
      window.location.href = `${process.env.NEXT_PUBLIC_BASE_URL_B2C}/b2b-login`;
    }
  }, [accessToken, channel, router]);
  const setCookie = useCallback(async () => {
    if (!("cookieStore" in window)) return;

    const value = await (window.cookieStore as any).get("homepage-version");
    if (!value) {
      const daysToLive = 30;
      await (window.cookieStore as any).set({
        name: "homepage-version",
        value: "new",
        expires: Date.now() + daysToLive * 24 * 60 * 60 * 1000
      });
    }
  }, []);

  const { customerKey } = useJwtToken();

  const addIdInGrowthbook = useCallback(
    (token: string) => {
      try {
        growthbook.setAttributes({
          id: customerKey
        });
      } catch {
        // eslint-disable-next-line no-console
        console.log("The token is not valid");
      }
    },
    [customerKey]
  );

  useEffect(() => {
    if (channel === "jek") {
      mutateAsync()
        .then((response) => {
          setSnappTripToken(response?.token);
          if (response?.token) {
            addIdInGrowthbook(response?.token);
          }
          localStorage.setItem("snappTripToken", response?.token);
        })
        .catch((error) => {
          // eslint-disable-next-line no-console
          console.log("snapp trip token error:", error);
        });
    }
    const getSnapptripToken = localStorage.getItem("snappTripToken");
    if (getSnapptripToken) {
      addIdInGrowthbook(getSnapptripToken);
    }
    setCookie();
  }, [addIdInGrowthbook, channel, mutateAsync, setCookie]);

  return {
    headerProperties: {
      showAuth: pathsHasAuth.has(router.pathname),
      snappTripToken
    },
    snappTripToken,
    showSearch: pathsHasSearch.has(router.pathname)
  };
};
