import { useDebounce } from "@website/hooks";
import { FlightRounded } from "@website/icons";
import { useTranslation } from "@website/locale";
import { useCallback, useMemo, useState } from "react";
import { NoResult } from "../../../..";
import { useAirportsService, useSearchSuggestionsService } from "../services";

export const useOrigin = () => {
  const { t } = useTranslation();
  const [originQuery, setOriginQuery] = useState<string>("");
  const [selectedOrigin, setSelectedOrigin] = useState<string>("");
  const [selectedOriginItem, setSelectedOriginItem] = useState(null);
  const query = useDebounce(originQuery, 250);

  const { suggestions } = useSearchSuggestionsService();
  const { isLoading, data } = useAirportsService(query);

  const onChangeOriginQuery = useCallback((value: string) => {
    setOriginQuery(value);
  }, []);

  const originList = useMemo(
    () => (originQuery.length >= 3 ? data || [] : suggestions || []),
    [data, suggestions, originQuery]
  );

  const originListNormalized = useMemo(
    () =>
      originList.map((item) => ({
        id: item?.iataCode,
        title: item?.cityFaName,
        subtitle: item?.airports?.[0]?.faName,
        StartIcon: <FlightRounded />,
        Meta: (
          <div className="text-on-surface-high-emphasis text-caption">
            {item.iataCode}
          </div>
        )
      })),
    [originList]
  );

  const onSelectOrigin = useCallback(
    (id: string) => {
      setSelectedOrigin(id);
      const originItem = originList.find(
        (origin: any) => `${origin.iataCode}` === `${id}`
      );
      setOriginQuery("");
      setSelectedOriginItem(originItem);
    },
    [originList]
  );

  const onChangeOrigin = useCallback((item: any) => {
    setSelectedOrigin(item?.iataCode || "");
    setOriginQuery("");
    setSelectedOriginItem(item);
  }, []);

  return {
    selectedOriginItem,
    selectedOriginText: selectedOriginItem?.cityFaName || "",
    originQuery,
    selectedOrigin,
    onChangeOrigin,
    onSelectOrigin,
    onChangeOriginQuery,
    originList,
    originLoading: isLoading,
    originListNormalized,
    OriginNoResult:
      originQuery?.length >= 3 && !isLoading && data && data.length === 0 ? (
        <NoResult query={originQuery} />
      ) : undefined,
    originLabel: t("homepage.flights.main_search.origin")
  };
};
