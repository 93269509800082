import { useTranslation } from "@website/locale";
import { Button } from "@website/ui";
import { deepCompare } from "@website/utils";
import { isEmpty, omit } from "lodash";
import { useRouter } from "next/router";
import { memo, useCallback } from "react";
import { ReserveComment } from "../reserve-comment";
import { ReserveCommentPopup } from "./reserve-comment-popup";
import { ReserveCommentsPropertiesLayoutTypes } from "./reserve-comments-layout.types";

const Component = (properties: ReserveCommentsPropertiesLayoutTypes) => {
  const router = useRouter();
  const {
    comments,
    lastReviewId,
    onLoadMore,
    isLoading,
    rate,
    onLoadFilter,
    filters
  } = properties;
  const { t } = useTranslation();

  const modal_key = "modal";
  const open = router.query[modal_key] === "comments";

  const toggleModal = useCallback(
    (statue: boolean) => {
      if (statue)
        return router.push({
          pathname: router.pathname,
          query: { ...router.query, [modal_key]: "comments" }
        });

      if (onLoadFilter && !isEmpty(filters)) {
        onLoadFilter(filters);
      }
      return router.push(
        {
          pathname: router.pathname,
          query: {
            ...omit(
              router?.query,
              "popover",
              "modal",
              Object.keys(router?.query).filter((item) =>
                item.startsWith("filter_")
              )
            )
          }
        },
        undefined,
        { shallow: true, scroll: false }
      );
    },

    [filters, onLoadFilter, router]
  );

  if (comments?.length === 0 && !router?.query?.["modal"]) return null;

  return (
    <div className="flex flex-col gap-6">
      <div className="flex flex-col items-center md:flex-row md:justify-between md:gap-4">
        <div className="w-full">
          <div className="flex flex-row justify-between">
            <h2 className="text-subtitle-1 md:text-headline-6">
              {t("comments.passengers_comments")}
            </h2>
            {(comments?.length ?? 0) >= 5 && (
              <Button
                color="secondary"
                onClick={toggleModal.bind(null, true)}
                size="small"
                variant="outline"
              >
                {t("comments.view_all_comments")}
              </Button>
            )}
          </div>
          <div className="text-caption text-on-surface-medium-emphasis pb-4 pt-1 md:pb-0 md:pt-2">
            {/*{t("comments.description")}*/}
          </div>
        </div>
        {/*<Button*/}
        {/*  color="secondary"*/}
        {/*  size="small"*/}
        {/*  variant="outline"*/}
        {/*>*/}
        {/*  ثبت دیدگاه*/}
        {/*</Button>*/}
      </div>
      {comments
        ?.slice(0, 5)
        .map((comment) => <ReserveComment key={comment?.id} {...comment} />)}
      <ReserveCommentPopup
        comments={comments}
        filters={filters}
        isLoading={isLoading}
        lastReviewId={lastReviewId}
        onClose={toggleModal.bind(null, false)}
        onLoadFilter={onLoadFilter}
        onLoadMore={onLoadMore}
        open={open}
        rate={rate}
      />
    </div>
  );
};
export const ReserveCommentsLayout = memo(Component, deepCompare);
