import { MaterialIcon } from "@website/icons";
import { deepCompare } from "@website/utils";
import { isEmpty } from "lodash";
import { useRouter } from "next/router";
import { memo } from "react";
import { ReserveCommentsFilterItem } from "./reserve-comment-filter-item";
import { ReserveCommentFilterTypes } from "./reserve-comment-filter.types";

const Component = ({
  onLoadFilter,
  filters,
  isInsideDetail
}: ReserveCommentFilterTypes) => {
  const router = useRouter();
  return (
    // const filterCount = Object.keys(router?.query).filter((item) =>
    //   item.startsWith("filter_")
    // ).length;
    <div className="flex flex-row items-center gap-4">
      <div className="text-button">
        <MaterialIcon name="tune" /> <span>فیلترها</span>
      </div>
      {!isEmpty(filters) && (
        <ReserveCommentsFilterItem
          filterName={{
            fa: `${
              router?.query?.["filter_attitude"] === "all"
                ? "همه نظرات"
                : router?.query?.["filter_attitude"] === "positive"
                  ? "نظرات مثبت"
                  : router?.query?.["filter_attitude"] === "negative"
                    ? "نظرات منفی"
                    : "نوع نظرات"
            }`,
            en: `${Object.keys(filters)[0]}`
          }}
          isInsideDetail={isInsideDetail}
          onLoadFilter={onLoadFilter}
          radioItems={[
            { label: "همه نظرات", value: "all" },
            { label: "نظرات مثبت", value: "positive" },
            { label: "نظرات منفی", value: "negative" }
          ]}
          startIconName="stars"
        />
      )}
      {/*{Boolean(filterCount) && (*/}
      {/*  <Button*/}
      {/*    color="secondary"*/}
      {/*    onClick={() => {*/}
      {/*      if (onLoadFilter) {*/}
      {/*        onLoadFilter({ attitude: "all" });*/}
      {/*      }*/}
      {/*      router.push(*/}
      {/*        {*/}
      {/*          pathname: router.pathname,*/}
      {/*          query: {*/}
      {/*            ...omit(*/}
      {/*              router?.query,*/}
      {/*              Object.keys(router?.query).filter((item) =>*/}
      {/*                item.startsWith("filter_")*/}
      {/*              )*/}
      {/*            )*/}
      {/*          }*/}
      {/*        },*/}
      {/*        undefined,*/}
      {/*        { shallow: true, scroll: false }*/}
      {/*      );*/}
      {/*    }}*/}
      {/*    size="small"*/}
      {/*    variant="text"*/}
      {/*  >*/}
      {/*    {` حذف فیلتر ها (${numberConvert(filterCount, {*/}
      {/*      locale: router.locale*/}
      {/*    })}) `}*/}
      {/*  </Button>*/}
      {/*)}*/}
    </div>
  );
};
export const ReserveCommentsFilter = memo(Component, deepCompare);
