import { useTranslation } from "@website/locale";
import { Button, Modal } from "@website/ui";
import { useCallback, useEffect, useState } from "react";
export const NotificationPermission = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const { t } = useTranslation();

  const openNotificationPermissionModal = useCallback(() => {
    if (!window.webengage) {
      return setTimeout(() => openNotificationPermissionModal(), 1000);
    }

    if (Notification.permission === "default") {
      setIsModalOpen(true);
    }
  }, []);

  const closeModal = useCallback(() => {
    setIsModalOpen(false);
  }, []);

  const getNotificationPermission = useCallback(() => {
    closeModal();
    window.webengage.options("webpush.registerServiceWorker", false);
    window.webengage.webpush.prompt();
  }, [closeModal]);

  useEffect(() => {
    openNotificationPermissionModal();
  }, [openNotificationPermissionModal]);
  return (
    <Modal
      mobileHeight="auto"
      open={isModalOpen}
      showCloseButton
      title={t("homepage.description.notification.title")}
    >
      <div className="flex flex-col items-center">
        <p>{t("homepage.description.notification.description")}</p>
        <div className="m-4 flex w-48 flex-row justify-between">
          <Button color="secondary" onClick={getNotificationPermission}>
            فعالسازی
          </Button>
          <Button color="secondary" onClick={closeModal} variant="outline">
            لغو
          </Button>
        </div>
      </div>
    </Modal>
  );
};
