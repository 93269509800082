import { useTranslation } from "@website/locale";
import { useRouter } from "next/router";
import { useCallback, useState } from "react";

export const useSubmit = (
  destinationItem: any,
  addToHistory: (destination: any) => void,
  submitValidationCheck: () => boolean
) => {
  const { t } = useTranslation();
  const router = useRouter();
  const [submitLoading, setSubmitLoading] = useState<boolean>(false);
  const channel = process.env.NEXT_PUBLIC_CHANNEL_TYPE;

  const onSubmitClick = useCallback(() => {
    setSubmitLoading(true);
    if (submitValidationCheck()) {
      if (destinationItem) {
        addToHistory(destinationItem);

        const parameters = {
          country: destinationItem.name,
          Source: "SnappTrip",
          homepage_version: "new",
          source: "searchBox",
          ...router?.query
        };
        window?.webengage?.track("IF_visaSearch", {
          ...parameters,
          channel,
          source: "searchBox"
        });
        window?.dataLayer?.push({
          event: "visa-search",
          ecommerce: {
            items: [parameters]
          }
        });

        window.location.href = `${
          process.env.NEXT_PUBLIC_BASE_URL_B2C
        }/visa/country/${destinationItem.name}?${
          channel === "jek" ? `token=${router?.query?.token}` : ""
        }&source=searchBox&country=${destinationItem.name}`;
        setSubmitLoading(false);
      } else {
        setSubmitLoading(false);
      }
    } else {
      setSubmitLoading(false);
    }
  }, [
    submitValidationCheck,
    destinationItem,
    addToHistory,
    channel,
    router?.query
  ]);

  return { onSubmitClick, submitLoading, submitText: t("action.search") };
};
