import { observable } from "@legendapp/state";
import { persistObservable } from "@legendapp/state/persist";
import { ObservablePersistSessionStorage } from "@legendapp/state/persist-plugins/local-storage";
import { Nationality, PassengerStoreType } from "./store.types";

export const passengerInfoDefault = {
  mainPassengerName: "",
  mainPassengerFamily: "",
  mainPassengerNationality: Nationality.Iranian,
  mainPassengerNationalCode: "",
  mainPassengerMobileNumber: "",
  extraBedCount: 0,
  childCount: 0,
  infantCount: 0,
  roomId: ""
};
export const otherPassengersInfoDefault = {
  mainPassengerName: "",
  mainPassengerFamily: "",
  roomId: "",
  age: 0,
  roomIndex: 0
};

export const shoppingDefault = {
  purchaserName: "",
  purchaserFamily: "",
  purchaserEmail: "",
  isPurchaserSupervisor: false,
  supervisorName: "",
  supervisorFamily: "",
  supervisorMobileNumber: "",
  supervisorEmail: "",
  purchaserMobileNumber: "",
  requisition: "",
  passengerInfo: [] as Array<typeof passengerInfoDefault>,
  otherPassengersInfo: [] as Array<typeof otherPassengersInfoDefault>
};

export const tourBookingDefault = {
  buyer: {
    mobileNumber: "",
    firstName: "",
    lastName: ""
  },
  roomGuest: [{ passengers: [] }]
};

interface BuyerInfo {
  phoneNumber?: string;
  // fullName?: string;
  email?: string;
}

const flightsIntlBookingDefault: {
  buyerInfo?: BuyerInfo;
  passengers?: Array<PassengerStoreType>;
} = {
  buyerInfo: {},
  passengers: []
};

export const applicationStore = observable({
  isNative: false,
  shopping: shoppingDefault,
  tourBooking: tourBookingDefault,
  flightsIntlBooking: flightsIntlBookingDefault,
  bankName: ""
});

persistObservable(applicationStore, {
  local: "application_store",
  pluginLocal: ObservablePersistSessionStorage
});
