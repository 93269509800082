import { authStore, useSelector } from "@website/store";
import { useRouter } from "next/router";
import { FC, PropsWithChildren, useEffect } from "react";

const channel = process.env.NEXT_PUBLIC_CHANNEL_TYPE;
export const LoginB2BProvider: FC<PropsWithChildren> = ({ children }) => {
  const router = useRouter();
  const accessToken = useSelector(authStore.accessToken);

  useEffect(() => {
    if (channel === "b2b" && !accessToken && router.asPath !== "/b2b-login") {
      window.location.href = `${process.env.NEXT_PUBLIC_BASE_URL_B2B}/b2b-login`;
    }
  }, [accessToken, router]);

  return children;
};
