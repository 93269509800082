import { useTranslation } from "@website/locale";
import { deepCompare, numberConvert } from "@website/utils";
import { toString } from "lodash";
import { useRouter } from "next/router";
import { memo } from "react";
import { ReserveRateFooter } from "./reserve-rate-footer";
import { ReserveRateHeader } from "./reserve-rate-header";
import { ReserveProgressBar } from "./reserve-rate-progress-bar";
import {
  ReserveRateEnum,
  ReserveRatePropertiesTypes,
  ReserveRateTypes
} from "./reserve-rate.types";

const Component = (properties: ReserveRatePropertiesTypes) => {
  const {
    rate,
    rateCount,
    offerCount,
    offerMessage,
    rateDetail,
    showTitle = true,
    size = "large",
    hasBackground = true,
    hotelType,
    pageTitle,
    pageDescription
  } = properties;
  const router = useRouter();
  const { t } = useTranslation();
  if (!rateCount) return null;
  return (
    <div>
      {pageTitle && pageDescription && (
        <script
          dangerouslySetInnerHTML={{
            __html: JSON.stringify({
              "@context": "https://schema.org/",
              "@type": "Review",
              author: {
                "@type": "Person",
                name: t("global.snapp_trip")
              },
              itemReviewed: {
                "@type": "Product",
                name: toString(pageTitle),
                image: "https://imageaddress.svg",
                aggregateRating: {
                  "@type": "AggregateRating",
                  ratingValue: toString(rate ?? 0),
                  ratingCount: toString(rateCount ?? 0),
                  reviewCount: toString(offerCount ?? 0),
                  bestRating: "5",
                  worstRating: "1"
                },
                description: toString(pageDescription),
                brand: t("global.snapp_trip")
              },
              reviewRating: {
                "@type": "Rating",
                ratingValue: toString(rate ?? 0),
                bestRating: "5",
                worstRating: "1"
              }
            })
          }}
          type="application/ld+json"
        />
      )}
      {showTitle && (
        <h2 className="text-subtitle-1 md:text-headline-6 mb-4">
          {t("rate.rate_residence", { residence: hotelType })}
        </h2>
      )}
      <div
        className={`flex min-w-[218px] flex-col gap-4 rounded-[8px] md:max-w-[864px] lg:max-w-[1200px] xl:rounded-[8px] ${
          size === "large"
            ? "xl:max-w-[1200px] xl:rounded-[8px] xl:px-10 xl:py-8"
            : ""
        } ${hasBackground ? "bg-dim-background p-6 xl:px-10 xl:py-8" : ""}`}
      >
        <div
          className={`flex flex-col gap-4 ${
            size === "large" ? "xl:flex-row xl:items-center xl:gap-[40px]" : ""
          }`}
        >
          <ReserveRateHeader properties={{ rate, rateCount, size }} />
          <div
            className={`bg-outline-thin hidden h-[72px] w-px ${
              size === "large" ? "xl:block" : ""
            }`}
          />
          <div
            className={`grid w-full gap-x-[40px] gap-y-4 ${
              size === "large" ? "xl:grid-cols-2" : ""
            }`}
          >
            {Object.keys(rateDetail)?.map((key) => (
              <div
                className="flex flex-row items-center gap-2 md:gap-4"
                key={key}
              >
                <div className="text-caption md:text-body-2 w-[152px] md:w-[178px]">
                  {ReserveRateEnum[key as ReserveRateTypes]}
                </div>
                <div className="grow">
                  <ReserveProgressBar
                    properties={{
                      limit: 5,
                      progress: Number(rateDetail[key as ReserveRateTypes])
                    }}
                  />
                </div>
                <div className="text-caption md:text-body-2">
                  {numberConvert(rateDetail[key as ReserveRateTypes], {
                    locale: router.locale
                  })}
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
      {Boolean(offerCount) && (
        <ReserveRateFooter properties={{ offerCount, offerMessage }} />
      )}
    </div>
  );
};
export const ReserveRate = memo(Component, deepCompare);
